

.darkmode--activated .terms-main{
    position: relative;
}
.darkmode--activated .terms-main .para-heading{
    color: black !important;
}
.darkmode--activated .terms-main .control{
    color: black !important;
}
.darkmode--activated .terms-main .para{
    color: black !important;
}
.darkmode--activated .terms-main b{
    color: black !important;
}


//======================= PRIVACY Start Here ========================//

.terms-main{
    margin: 128px 0;
    .container{
        .para-main{
            .wel-come{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .control{
                    color: $white-color;
                    margin-top: 40px;
                    font-weight: 600;
                    font-size: 18px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
            .foundation{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
            .smart{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
            .non{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .control{
                    color: $white-color;
                    margin-top: 40px;
                    font-weight: 600;
                    font-size: 18px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
            .how-do{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .control{
                    color: $white-color;
                    margin-top: 40px;
                    font-weight: 600;
                    font-size: 18px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
            .rules{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .control{
                    color: $white-color;
                    margin-top: 40px;
                    font-weight: 600;
                    font-size: 18px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
            .become{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
            .property{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .heading-2{
                    color: $white-color;
                    font-size: 35px;
                    margin-top: 30px;
                    margin-bottom: 20px;
                }
                .control{
                    color: $white-color;
                    margin-top: 40px;
                    font-weight: 600;
                    font-size: 18px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }  
            }
            .Cooperate{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
            .fees{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
            .about{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
            .Legal{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                b{
                    color: $white-color;
                    font-size: 20px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
        }
    }
}

//======================= PRIVACY Start End ========================//