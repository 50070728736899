@mixin site-transition {
    transition: all 0.5s ease-in-out;
    transition: 0.5s linear; /* vendorless fallback */
    -o-transition: 0.5s linear; /* opera */
    -ms-transition: 0.5s linear; /* IE 10 */
    -moz-transition: 0.5s linear; /* Firefox */
    -webkit-transition: 0.5s linear; /*safari and chrome */
}

@mixin site-btn-primary {
    padding: 10px 24px;
    background-color: $primary-color;
    border-radius: 4px;
    display: flex;
    align-items: center;
    transition: .5s;
    &:hover{
        background-color: $scondry-color;
        .right-arrow{
            margin-left: 16px;
            color: $white-color;
        }
        .btn-text{
            color: $white-color;
        }
    }
    .btn-text{
        @include body-s;
        font-family: $font-family-Rubik-Medium;
        color: $body-color-2;
        transition: .2s;
    }
    .right-arrow{
        color: $body-color-2;
        position: relative;
        top: 3px;
        margin-left: 8px;
        font-size: 24px;
        line-height: 32px;
        transition: .2s;
    }
}

@mixin site-btn-secondry {
    padding: 10px 24px;
    background-color: $scondry-color;
    border-radius: 4px;
    display: flex;
    align-items: center;
    transition: .5s;
    &:hover{
        background-color: $border-color;
        .right-arrow{
            margin-left: 16px;
        }
    }
    .btn-text{
        @include body-s;
        font-family: $font-family-Rubik-Medium;
        color: $white-color;
        transition: .2s;
    }
    .right-arrow{
        color: $white-color;
        position: relative;
        top: 3px;
        margin-left: 8px;
        font-size: 24px;
        line-height: 32px;
        transition: .2s;
    }
}

@mixin site-btn {
    padding: 12px 24px;
    border-radius: 4px;
    background-color: $border-color;
    color: $white-color;
    @include body-s;
    font-family: $font-family-Rubik-Medium;
    text-transform: capitalize;
    transition: .3s;
    &:hover{
        color: $body-color-2;
        background-color: $primary-color;
    }
}

@mixin body-l {
    font-size: $font-size-s-md;
    line-height: $line-height-f-md;
    font-family: $font-family-Rubik-Regular;
}
@mixin body-m {
    font-size: $font-size-sm;
    line-height: $line-height-s-md;
    font-family: $font-family-Rubik-Regular;
}
@mixin body-s {
    font-size: $font-size-xsm;
    line-height: $line-height-sm;
    font-family: $font-family-Rubik-Regular;
}

@mixin animation-4 {
    @keyframes animation-4 {
        0%{
            transform: translateY(0px);
        }
        33%{
            transform: translateY(-5px);
        }
        66%{
            transform: translateY(5px);
        }
        100%{
            transform: translateY(0px);
        }
    }
}

@mixin animation-2 {
    @keyframes animation-2 {
        0%{
            transform: translateY(0px);
        }
        33%{
            transform: translateY(-5px);
        }
        66%{
            transform: translateY(5px);
        }
        100%{
            transform: translateY(0px);
        }
    }
}

@mixin animation-3 {
    @keyframes animation-3 {
        0%{
            transform: translateY(0px);
        }
        33%{
            transform: translateY(5px);
        }
        66%{
            transform: translateY(-5px);
        }
        100%{
            transform: translateY(0px);
        }
    }
}

@mixin animation-1 {
    @keyframes animation-1 {
        0%{
            transform: translateY(0px);
        }
        33%{
            transform: translateY(-10px);
        }
        66%{
            transform: translateY(5px);
        }
        100%{
            transform: translateY(0px);
        }
    }
}

@mixin loader-1 {
    @keyframes loader-1 {
        0%{
            transform: scaleY(1);
        }
        50%{
            transform: scaleY(1.4);
        }
        100%{
            transform: scaleY(1);
        }
    }
}
@mixin loader-2 {
    @keyframes loader-2 {
        0%{
            transform: scaleY(1);
        }
        50%{
            transform: scaleY(2);
        }
        100%{
            transform: scaleY(1);
        }
    }
}
@mixin loader-3 {
    @keyframes loader-3 {
        0%{
            transform: scaleY(1);
        }
        50%{
            transform: scaleY(2.8);
        }
        100%{
            transform: scaleY(1);
        }
    }
}
@mixin loader-4 {
    @keyframes loader-4 {
        0%{
            transform: scaleY(1);
        }
        50%{
            transform: scaleY(3.6);
        }
        100%{
            transform: scaleY(1);
        }
    }
}
