.darkmode-layer--button {
    width: 2.9rem;
    height: 2.9rem;
    border-radius: 50%;
    right: -32px;
    bottom: 50% !important;
    left: unset;
}
.darkmode-toggle {
    background: #100f2c;
    width: 3rem;
    height: 3rem;
    position: fixed;
    border-radius: 50%;
    border: none;
    right: 32px;
    bottom: 50% !important;
    left: unset;
    cursor: pointer;
    transition: all 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
.darkmode-toggle::before{
    content: "☀️";
}
.darkmode-layer{
    mix-blend-mode: initial !important;
}
.darkmode--activated .darkmode-background {
    background-color: black !important;
}
.darkmode--activated .darkmode-toggle{
    background-color: black !important;
}  
 
.darkmode-toggle--white::before{
    content: "🌙";
} 


// Navbar

.darkmode--activated .navbar-marketplace .logo{
    mix-blend-mode: difference;
}
.darkmode--activated .navbar-marketplace .search-input .input{
    color: #999999 !important;
    background-color: #EEEEEE !important;
}
.darkmode--activated .navbar-marketplace .search-input .input::placeholder{
    color: #999999 !important;
}
.darkmode--activated .navbar-marketplace .search-input .search-icon{
    color: #999999 !important;
}
.darkmode--activated .navbar-marketplace .action .create-btn{
    border: 1px solid #CCCCCC !important;
    background-color: transparent !important;
    color: #666666 !important;
    position: relative !important;
}
.darkmode--activated .navbar-marketplace .action .create-btn:hover{
    border: 1px solid transparent !important;
    background-color: #892CDC !important;
    color: white !important;
}
.darkmode--activated .navbar-marketplace .action .wallet-btn{
    border: 1px solid transparent !important;
    background-color: #FFD700 !important;
    color: #000 !important;
    position: relative !important;
}
.darkmode--activated .navbar-marketplace .action .wallet-btn:hover{
    border: 1px solid #892CDC !important;
    background-color: #892CDC !important;
    color: white !important;
}
.darkmode--activated .navbar-marketplace .circle{
    background-color: #EEEEEE !important;
}
.darkmode--activated .navbar-marketplace .circle .burger{
    color: #892CDC !important;
}

// Navbar

// Hero Section
  
.darkmode--activated .hero-main{
    background-color: #EEEEEE !important;
    position: relative;
}
.darkmode--activated .hero-main .heading{
    color: black !important;
    position: relative;
}
.darkmode--activated .clock-hero{
    color: #892CDC !important;
    position: relative;
}
.darkmode--activated .hero-main .bit-main{
    color: #666666 !important;
}
.darkmode--activated .hero-main .bit-main .ETH{
    color: #892CDC !important;
} 
.darkmode--activated .hero-main .auction-main .auction{
    color: #892CDC !important;
} 

// Hero Section

// Live Auctions

.darkmode--activated .live-auctions{
    position: relative;
}
.darkmode--activated .live-auctions .wrapper .heading{
    color: black !important;
}
.darkmode--activated .live-auctions .wrapper .line{
    background-color: #CCCCCC !important;
}
.darkmode--activated .live-auctions .wrapper .action .acution-btn{
    border: 1px solid transparent !important;
    background-color: #FFD700 !important;
    color: #000 !important;
}
.darkmode--activated .live-auctions .wrapper .action .acution-btn:hover{
    border: 1px solid #892CDC !important;
    background-color: #892CDC !important;
    color: white !important;
}
.darkmode--activated .live-auctions .art{
    background-color: #EEEEEE !important;
}
.darkmode--activated .live-auctions .art .abstract{
    color: #000 !important;
}
.darkmode--activated .live-auctions .art .username{
    color: #999999 !important;
}
.darkmode--activated .live-auctions .bit-main-otr{
    background-color: #FFD700 !important;
}
.darkmode--activated .live-auctions .bit-main-otr .highest-bit{
    color: #666666 !important;
}
.darkmode--activated .live-auctions .bit-main-otr .ending-main .ending{
    color: #666666 !important;
}
.darkmode--activated .live-auctions .bit-main-otr .ending-main .dot{
    color: #666666 !important;
}
.darkmode--activated .live-auctions .bit-main-otr .dot{
    color: #666666 !important;
}
.darkmode--activated .live-auctions .bit-main-otr .ending-main .price{
    color: #000 !important;
}
.darkmode--activated .live-auctions .bit-main-otr .ETH{
    color: #000 !important;
}
.darkmode--activated .live-auctions .bit-main-otr .ending-main .clock-auctions1{
    color: #000 !important;
}
.darkmode--activated .live-auctions .bit-main-otr .ending-main .clock-auctions2{
    color: #000 !important;
}
.darkmode--activated .live-auctions .bit-main-otr .ending-main .clock-auctions3{
    color: #000 !important;
}
.darkmode--activated .live-auctions .bit-main-otr .ending-main .clock-auctions4{
    color: #000 !important;
}
.darkmode--activated .live-auctions .bit-main-otr .ending-main .clock-auctions5{
    color: #000 !important;
}
.darkmode--activated .live-auctions .bit-main-otr .ending-main .clock-auctions6{
    color: #000 !important;
}
.darkmode--activated .live-auctions .bit-main-otr .ending-main .clock-auctions1{
    color: #000 !important;
}
.darkmode--activated .live-auctions .bit-main-otr .ending-main .clock-auctions7{
    color: #000 !important;
}
.darkmode--activated .live-auctions .bit-main-otr .ending-main .clock-auctions8{
    color: #000 !important;
}

// Live Auctions

//Featured Creators

.darkmode--activated .featured-creators {
    position: relative;
}
.darkmode--activated .featured-creators .wrapper .heading{
    color: #000 !important;
}
.darkmode--activated .featured-creators .wrapper .line{
    background-color: #CCCCCC !important;
}
.darkmode--activated .featured-creators .wrapper .action .acution-btn{
    border: 1px solid transparent !important;
    background-color: #FFD700 !important;
    color: #000 !important;
}
.darkmode--activated .featured-creators .wrapper .action .acution-btn:hover{
    border: 1px solid #892CDC !important;
    background-color: #892CDC !important;
    color: white !important;
}
.darkmode--activated .featured-creators .creator-name{
    color: black !important;
}
.darkmode--activated .featured-creators .creator-address{
    color: #999999 !important;
}

//Featured Creators

//NFTDAO

.darkmode--activated .nftdao{
    background-color: #EEEEEE !important;
    position: relative;
}
.darkmode--activated .nftdao .heading{
    color: black !important;
}
.darkmode--activated .nftdao .desc{
    color: #999999 !important;
}
.darkmode--activated .nftdao .action .create-btn{
    border: 1px solid #CCCCCC !important;
    background-color: transparent !important;
    color: #666666 !important;
    position: relative !important;
}
.darkmode--activated .nftdao .action .create-btn:hover{
    border: 1px solid transparent !important;
    background-color: #892CDC !important;
    color: white !important;
}
.darkmode--activated .nftdao .action .wallet-btn{
    border: 1px solid transparent !important;
    background-color: #FFD700 !important;
    color: #000 !important;
    position: relative !important;
}
.darkmode--activated .nftdao .action .wallet-btn:hover{
    border: 1px solid #892CDC !important;
    background-color: #892CDC !important;
    color: white !important;
}

//NFTDAO

//Footer
.darkmode--activated .footer{
    position: relative;
}
.darkmode--activated .footer .social-media{
    border-bottom: 1px solid #CCCCCC !important;
}
.darkmode--activated .footer .social-in .dark{
    color: #666666 !important;
    transition: .2s;
}    
.darkmode--activated .footer .social-in .dark:hover{
    color: #892CDC !important;
}
.darkmode--activated .footer .logo-otr .logo{
    mix-blend-mode: difference;
} 
.darkmode--activated .footer .right .link{
    color: #666666 !important;
    transition: .2s;
}
.darkmode--activated .footer .right .link:hover{
    color: #892CDC !important;
}
.darkmode--activated .footer .left .copy{
    color: #666666 !important;
}
.darkmode--activated .footer .left .logo-link{
    color: #892CDC !important;
}

//Footer

//Overlay Menu

.darkmode--activated .overlay-menu{
    background-color: white !important;
}
.darkmode--activated .overlay-menu .mobile-header .logo-otr .logo{
    mix-blend-mode: difference;
}
.darkmode--activated .overlay-menu .mobile-header .cross-otr{
    background-color: #EEEEEE !important;
}
.darkmode--activated .overlay-menu .mobile-header .cross-inr{
    color: #892CDC !important;
}
.darkmode--activated .overlay-menu .search-input .input{
    color: #999999 !important;
    background-color: #EEEEEE !important;
}
.darkmode--activated .overlay-menu .search-input .input::placeholder{
    color: #999999 !important;
}
.darkmode--activated .overlay-menu .search-input .search-icon{
    color: #999999 !important;
}
.darkmode--activated .overlay-menu .overlay-contentt .overlay-a{
    color: #999999 !important;
    transition: .3s;
}
.darkmode--activated .overlay-menu .overlay-contentt .overlay-a:hover{
    color: #892CDC !important;
}
.darkmode--activated .overlay-menu .action .create-btn{
    border: 1px solid #CCCCCC !important;
    background-color: transparent !important;
    color: #666666 !important;
    position: relative !important;
}
.darkmode--activated .overlay-menu .action .create-btn:hover{
    border: 1px solid transparent !important;
    background-color: #892CDC !important;
    color: white !important;
}
.darkmode--activated .overlay-menu .action .wallet-btn{
    border: 1px solid transparent !important;
    background-color: #FFD700 !important;
    color: #000 !important;
    position: relative !important;
}
.darkmode--activated .overlay-menu .action .wallet-btn:hover{
    border: 1px solid #892CDC !important;
    background-color: #892CDC !important;
    color: white !important;
}

//Overlay Menu



// .darkmode--activated .art{
//     background-color: #EEEEEE !important;
//     position: relative;
// }
// .darkmode--activated .bit-main-otr{
//     background-color: #FFD700 !important;
//     position: relative;
// }
// .darkmode--activated .clock-hero{
//     color: #892CDC !important;
//     position: relative;
// }
// .darkmode--activated .clock-auctions1,.clock-auctions2,.clock-auctions3,.clock-auctions4,.clock-auctions5,.clock-auctions6,.clock-auctions7,.clock-auctions8{
//     color: black !important;
//     position: relative;
// }



.overlay-menu{
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    background-color: $body-color;
    overflow-y: hidden;
    transition: 0.5s;
    .container{
        position: relative;
        .mobile-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 24px 12px 0 12px;
            position: absolute;
            top: 0;
            left: 0;
            .logo-otr{
                .logo{
                    width: 180px;
                }
            }
            .cross-otr{
                width: 52px;
                height: 52px;
                border-radius: 100%;
                background-color: $body-color-2;
                position: relative;
                @include breakpoint($secreen-min-lg){
                    display: none;
                }
                .cross-inr{
                    color: $body-text-color;
                    font-size: 15px;
                    position: absolute;
                    top: 53%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
        .overlay-contentt{
            .overlay-conetnt-inr{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100vh;
                text-align: center;
                .search-input{
                    position: relative;
                    width: 470px;
                    @include breakpoint($secreen-xs){
                        width: 100%;
                    }
                    .input{
                        width: 100%;
                        padding: 10px 24px;
                        border-radius: 4px;
                        border: none;
                        @include body-s;
                        font-family: $font-family-Rubik-Regular;
                        background-color: $body-color-2;
                        color: $body-text-color;
                        text-transform: capitalize;
                        &::placeholder{
                            color: $body-text-color;
                        }
                        &:focus{
                            border: none;
                            outline: none;
                        }
                    }
                    .search-icon{
                        position: absolute;
                        top: 50%;
                        right: 24px;
                        transform: translateY(-50%);
                        color: $body-text-color;
                        line-height: 0;
                        @include breakpoint($secreen-xs){
                            display: none;
                        }
                    }
                }
                .overlay-ul{
                    margin: 32px 0;
                    .overlay-li{
                        &:not(:last-child){
                            margin-bottom: 8px;
                        }
                        .overlay-a{
                            @include body-l;
                            font-family: $font-family-Rubik-Medium;
                            color: $body-text-color;
                            transition: .3s;
                            &:hover{
                                color: $white-color;
                            }
                        }
                    }
                }
                .action{
                    display: flex;
                    .create-btn{
                        @include site-btn;
                        border: 1px solid transparent;
                    }
                    .wallet-btn{
                        @include site-btn;
                        background-color: $primary-color;
                        color: $body-color-2;
                        border: 1px solid transparent;
                        &:hover{
                            background-color: $border-color;
                            color: $white-color;
                        }
                    }
                    .right{
                        margin-left: 12px;
                    }
                }
            }
        }
    }
}

//======================= Navbar Start Here ========================//

.navbar-marketplace{
    .container{
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px 0;
            .logo-otr{
                .logo-inr{
                    .logo{
                        width: 180px;
                    }
                }
            }
            .search-input{
                position: relative;
                width: 470px;
                @include breakpoint($secreen-max-lg){
                    display: none;
                }
                .input{
                    width: 100%;
                    padding: 10px 24px;
                    border-radius: 4px;
                    border: none;
                    @include body-s;
                    font-family: $font-family-Rubik-Regular;
                    background-color: $body-color-2;
                    color: $body-text-color;
                    text-transform: capitalize;
                    &::placeholder{
                        color: $body-text-color;
                    }
                    &:focus{
                        border: none;
                        outline: none;
                    }
                }
                .search-icon{
                    position: absolute;
                    top: 50%;
                    right: 24px;
                    transform: translateY(-50%);
                    color: $body-text-color;
                    line-height: 0;
                }
            }
            .mobile-input-main{
                width: 56px;
                height: 56px;
                background-color: $border-color;
                border-radius: 4px;
                position: relative;
                margin-left: 24px;
                transition: .3s;
                &:hover{
                    background-color: $primary-color;
                    .search-iicon{
                        color: black;
                    }
                }
                @include breakpoint($secreen-xl){
                    display: none;
                }
                .search-iicon{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: $white-color;
                    transition: .3s;
                }
            }
            .modal-custom{
                .modal-dialog-custom{
                    .modal-content-custom{
                        background-color: transparent;
                        border: none;
                        .cross-main{
                            margin-left: auto;
                            .cross{
                                color: $white-color;
                                font-size: 20px;
                            }
                        }
                        .input-main{
                            padding: 20px 24px;
                            background-color: $border-color;
                            border-radius: 4px;
                            .mobile-input{
                                width: 100%;
                                padding: 10px 24px;
                                border-radius: 4px;
                                border: none;
                                @include body-s;
                                font-family: $font-family-Rubik-Regular;
                                background-color: $body-color-2;
                                color: $body-text-color;
                                text-transform: capitalize;
                                &::placeholder{
                                    color: $body-text-color;
                                }
                                &:focus{
                                    border: none;
                                    outline: none;
                                }
                            }
                        }
                    }
                }
            }
            .action{
                display: flex;
                @include breakpoint($secreen-max-md){
                    display: none;
                }
                .create-btn{
                    @include site-btn;
                    border: 1px solid transparent;
                }
                .wallet-btn{
                    @include site-btn;
                    background-color: $primary-color;
                    color: $body-color-2;
                    border: 1px solid transparent;
                    &:hover{
                        background-color: $border-color;
                        color: $white-color;
                    }
                }
                .right{
                    margin-left: 24px;
                }
            }
            .circle{
                width: 52px;
                height: 52px;
                border-radius: 100%;
                background-color: $body-color-2;
                position: relative;
                @include breakpoint($secreen-min-lg){
                    display: none;
                }
                .burger{
                    color: $body-text-color;
                    font-size: 20px;
                    position: absolute;
                    top: 53%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}

//======================= Navbar Start End ========================//


//======================= Hero Start Here ========================//

.hero-main{
    padding: 96px 0;
    background-color: $body-color-2;
    .container{
        .row-custom{
            align-items: center;
            .col-otr{
                .col-inr{
                    padding-right: 100px;
                    @include breakpoint($secreen-max-md){
                        padding: 0 0 60px 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                    }
                    .user-main{
                        display: flex;
                        align-items: center;
                        padding: 0 0 16px 0;
                        @include breakpoint($secreen-lg){
                            display: none;
                        }
                        .user-img{
                            width: 32px;
                            border-radius: 4px;
                        }
                        .right{
                            margin-left: 16px;
                        }
                    }
                    .heading{
                        color: $white-color;
                        @include breakpoint($secreen-max-lg){
                            font-size: 52px;
                            line-height: 72px;
                        }
                    }
                    .bit-main{
                        display: flex;
                        align-items: center;
                        color: $body-text-color;
                        @include body-s;
                        padding: 16px 0;
                        @include breakpoint($secreen-max-lg){
                            padding: 12px 0;
                        }
                        .dot{
                            margin: 0 5px;
                        }
                        .ETH{
                            color: $primary-color;
                        }
                    }
                    .auction-main{
                        .auction{
                            color: $body-text-color;
                            @include body-s;
                            margin: 0 0 4px 0;
                        }
                        .clock-hero{
                            color: $primary-color;
                            font-size: $font-size-lg;
                            line-height: $line-height-lg;
                            font-family: $font-family-IBMPlexSans-SemiBold;
                            @include breakpoint($secreen-xs){
                                font-size: 32px;
                                line-height: 44px;
                            }
                        }
                    }
                    .action-otr{
                        display: flex;
                        margin: 40px 0 0 0;
                        @include breakpoint($secreen-max-lg){
                           margin: 24px 0 0 0;
                        }
                        .action-inr{
                            @include site-btn-primary;
                        }
                    }
                }
            }
            .col-img{
                .col-img-inr{
                    .hero-img{
                        border-radius: 4px;
                    } 
                }
            }
        }
    }
}

//======================= Hero End Here ========================//

//======================= Live Auctions Start Here ========================//

.live-auctions{
    margin: 128px 0;
    .container{
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;
            @include breakpoint($secreen-xs){
                flex-direction: column;
                justify-content: center;
            }
            .heading{
                color: $white-color;
                @include breakpoint($secreen-xs){
                    text-align: center;
                    margin-bottom: 24px;
                }
            }
            .line{
                flex-grow: 1;
                height: 1px;
                background-color: $border-color;
                margin: 0 24px;
            }
            .action{
                .acution-btn{
                    @include site-btn;
                    border: 1px solid transparent !important;
                }
            }
        }
        .row-custom{
            margin: 72px 0 0 0;
            @include breakpoint($secreen-max-sm){
                justify-content: center;
            }
            @include breakpoint($secreen-xs){
                width: 400px;
                margin: 72px auto 0 auto;
            }
            @include breakpoint($secreen-xxs){
                width: 100%;
            }
            .col-otr{
                .col-inr{
                    &:hover{
                        cursor: pointer;
                    }
                    .img-otr{
                        .auction-img{
                            width: auto;
                            border-radius: 4px;
                        }
                    }
                    .art{
                        padding: 20px 24px 28px 24px;
                        background-color: $body-color-2;
                        .abstract{
                            color: $white-color;
                            @include body-m;
                            font-family: $font-family-Rubik-Regular;
                        }
                        .user-profile{
                            display: flex;
                            align-items: center;
                            margin: 12px 0 0 0;
                            .user-img{
                                width: 32px;
                                height: 32px;
                                border-radius: 4px;
                            }
                            .username{
                                @include body-s;
                                color: $body-text-color;
                                margin-left: 12px;
                            }
                        }
                    }
                    .bit-main-otr{
                        padding: 16px 24px;
                        background-color: $border-color;
                        .bit-inr{
                            display: flex;
                            align-items: center;
                            @include body-s;
                            color: $body-text-color;
                            margin: 0 0 4px 0;
                            @include breakpoint($secreen-lg){
                                flex-direction: column;
                                align-items: baseline;
                            }
                            .dot{
                                margin: 0 8px;
                                @include breakpoint($secreen-lg){
                                    display: none;
                                }
                            }
                            .ETH{
                                color: $primary-color;
                            }
                        }
                        .ending-main{
                            display: flex;
                            align-items: center;
                            @include body-s;
                            color: $body-text-color;
                            @include breakpoint($secreen-lg){
                                flex-direction: column;
                                align-items: baseline;
                                margin: 4px 0 0 0;
                            }
                            .dot{
                                margin: 0 8px;
                                @include breakpoint($secreen-lg){
                                    display: none;
                                }
                            }
                            .clock-auctions1,.clock-auctions2,.clock-auctions3,.clock-auctions4,.clock-auctions5,.clock-auctions6,.clock-auctions7,.clock-auctions8{
                                color: $primary-color;
                            }
                            .price{
                                @include body-s;
                                color: $primary-color;
                            }
                        }
                    }
                }
                .box1,.box2,.box3,.box4{
                    margin-bottom: 30px;
                }
                .box5,.box6{
                    @include breakpoint($secreen-max-md){
                        margin-bottom: 30px;
                    }
                }
                .box7{
                    @include breakpoint($secreen-max-sm){
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}

//======================= Live Auctions End Here ========================//

//======================= Featured Creators Start Here ========================//

.featured-creators{
    margin: 128px 0;
    .container{
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;
            @include breakpoint($secreen-xs){
                flex-direction: column;
                align-items: baseline;
            }
            .heading{
                color: $white-color;
                @include breakpoint($secreen-xs){
                    margin-bottom: 24px;
                }
            }
            .line{
                flex-grow: 1;
                height: 1px;
                background-color: $border-color;
                margin: 0 24px;
            }
            .action{
                .acution-btn{
                    @include site-btn;
                    border: 1px solid transparent !important;
                }
            }
        }
        .row-custom{
            margin: 72px 0 0 0;
            justify-content: space-between;
            .col-otr{
                .col-inr{
                    .featured-link{
                        display: flex;
                        align-items: center;
                        .img-otr{
                            width: 52px;
                            height: 52px;
                            .featured-img{
                                border-radius: 4px;
                            }
                        }
                        .creator-user{
                            margin-left: 16px;
                            .creator-name{
                                color: $white-color;
                                @include body-m;
                                font-family: $font-family-Rubik-Medium;
                            }
                            .creator-address{
                                @include body-s;
                                color: $body-text-color;
                            }
                        }
                    }
                }
                .box1,.box2,.box3,.box4,.box5,.box6,.box7,.box8{
                    margin-bottom: 30px;
                }
                @include breakpoint($secreen-max-sm){
                    .box9,.box10{
                        margin-bottom: 30px;
                    }
                }
                @include breakpoint($secreen-xs){
                    .box11{
                        margin-bottom: 30px;
                    }
                }
            }
        } 
    }
}

//======================= Featured Creators End Here ========================//

//======================= NFTDAO Start Here ========================//

.nftdao{
    background-color: $body-color-2;
    padding: 128px 0;
    margin: 128px 0;
    .container{
        .wrapper{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            .heading{
                color: $white-color;
            }
            .desc{
                @include body-m;
                color: $body-text-color;
                padding: 24px 0 40px 0;
            }
            .action{
                display: flex;
                .create-btn{
                    @include site-btn;
                    border: 1px solid transparent;
                }
                .wallet-btn{
                    @include site-btn;
                    background-color: $primary-color;
                    color: $body-color-2;
                    border: 1px solid transparent;
                    &:hover{
                        background-color: $border-color;
                        color: $white-color;
                    }
                }
                .right{
                    margin-left: 24px;
                }
            }
        }
    }
}

//======================= NFTDAO End Here ========================//

//======================= Footer Start Here ========================//

.footer{
    .container{
        .wrapper{
            .social-media{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                padding: 0 0 40px 0;
                border-bottom: 1px solid $border-color;
                @include breakpoint($secreen-max-md){
                   justify-content: center;
                }
                .social{
                    .social-in{
                        color: $body-text-color;
                        transition: .3s;
                        @include breakpoint($secreen-max-md){
                            margin-right: 60px;
                        }
                        &:hover{
                            color: $white-color;
                        }
                    }
                }
            } 
            .copy-right{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 40px 0;
                @include breakpoint($secreen-max-md){
                    flex-direction: column;
                    justify-content: center;
                }
                .left{
                    display: flex;
                    align-items: center;
                    @include breakpoint($secreen-max-md){
                        margin-bottom: 4px;
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;
                    }
                    .logo-otr{
                        @include breakpoint($secreen-max-md){
                            margin-bottom: 20px;
                        }
                        .logo{
                            width: 180px;
                            margin-right: 24px;
                        }
                    }
                    .copy{
                        @include body-s;
                        color: $body-text-color;
                        .logo-link{
                            color: $primary-color;
                            font-family: $font-family-Rubik-Regular;
                            display: inline;
                        }
                    }
                }
                .right{
                    display: flex;
                    align-items: center;
                    text-align: center;
                    @include breakpoint($secreen-xxs){
                        flex-direction: column
                    }
                    .link{
                        @include body-s;
                        color: $body-text-color;
                        transition: .3s;
                        display: inline;
                        &:hover{
                            color: $white-color;
                        }
                    }
                    .dot{
                        color: $body-text-color;
                        margin: 0 5px;
                        @include breakpoint($secreen-xxs){
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

//======================= Footer End Here ========================//