

// Navbar

.darkmode--activated .navbar-inr-page .logo{
    mix-blend-mode: difference;
}
.darkmode--activated .navbar-inr-page .search-input .input{
    color: #999999 !important;
    background-color: #EEEEEE !important;
}
.darkmode--activated .navbar-inr-page .search-input .input::placeholder{
    color: #999999 !important;
}
.darkmode--activated .navbar-inr-page .search-input .search-icon{
    color: #999999 !important;
}
.darkmode--activated .navbar-inr-page .action .create-btn{
    border: 1px solid #CCCCCC !important;
    background-color: transparent !important;
    color: #666666 !important;
    position: relative !important;
}
.darkmode--activated .navbar-inr-page .action .create-btn:hover{
    border: 1px solid transparent !important;
    background-color: #892CDC !important;
    color: white !important;
}
.darkmode--activated .navbar-inr-page .action .wallet-btn{
    border: 1px solid transparent !important;
    background-color: #FFD700 !important;
    color: #000 !important;
    position: relative !important;
}
.darkmode--activated .navbar-inr-page .action .wallet-btn:hover{
    border: 1px solid #892CDC !important;
    background-color: #892CDC !important;
    color: white !important;
}
.darkmode--activated .navbar-inr-page .circle{
    background-color: #EEEEEE !important;
}
.darkmode--activated .navbar-inr-page .circle .burger{
    color: #892CDC !important;
}

// Navbar

//All Artwork

.darkmode--activated .all-artwork{
    position: relative;
}
.darkmode--activated .all-artwork .heading{
    color: black !important;
}
.darkmode--activated .all-artwork .nav-pills-custom::after{
    background-color: #EEEEEE !important;
}
.darkmode--activated .all-artwork .nav-pills-custom .nav-link-custom{
    color: #666666 !important;
}
.darkmode--activated .all-artwork .nav-pills-custom .active{
    color: #892CDC !important;
    border-bottom: 1px solid #892CDC !important;
}
.darkmode--activated .all-artwork .art{
    background-color: #EEEEEE !important;
}
.darkmode--activated .all-artwork .art .abstract{
    color: #000 !important;
}
.darkmode--activated .all-artwork .art .username{
    color: #999999 !important;
}
.darkmode--activated .all-artwork .bit-main-otr{
    background-color: #FFD700 !important;
}
.darkmode--activated .all-artwork .bit-main-otr .highest-bit{
    color: #666666 !important;
}
.darkmode--activated .all-artwork .bit-main-otr .ending-main .ending{
    color: #666666 !important;
}
.darkmode--activated .all-artwork .bit-main-otr .ending-main .dot{
    color: #666666 !important;
}
.darkmode--activated .all-artwork .bit-main-otr .dot{
    color: #666666 !important;
}
.darkmode--activated .all-artwork .bit-main-otr .ending-main .price{
    color: #000 !important;
}
.darkmode--activated .all-artwork .bit-main-otr .ETH{
    color: #000 !important;
}
.darkmode--activated .all-artwork .bit-main-otr .ending-main .clock-auctions1{
    color: #000 !important;
}
.darkmode--activated .all-artwork .bit-main-otr .ending-main .clock-auctions2{
    color: #000 !important;
}
.darkmode--activated .all-artwork .bit-main-otr .ending-main .clock-auctions3{
    color: #000 !important;
}
.darkmode--activated .all-artwork .bit-main-otr .ending-main .clock-auctions4{
    color: #000 !important;
}
.darkmode--activated .all-artwork .bit-main-otr .ending-main .clock-auctions5{
    color: #000 !important;
}
.darkmode--activated .all-artwork .bit-main-otr .ending-main .clock-auctions6{
    color: #000 !important;
}
.darkmode--activated .all-artwork .bit-main-otr .ending-main .clock-auctions1{
    color: #000 !important;
}
.darkmode--activated .all-artwork .bit-main-otr .ending-main .clock-auctions7{
    color: #000 !important;
}
.darkmode--activated .all-artwork .bit-main-otr .ending-main .clock-auctions8{
    color: #000 !important;
}

//All Artwork

//Footer
.darkmode--activated .footer-iiner-page{
    position: relative;
    background-color: #EEEEEE !important;
}
.darkmode--activated .footer-iiner-page .social-media{
    border-bottom: 1px solid #CCCCCC !important;
}
.darkmode--activated .footer-iiner-page .social-in .dark{
    color: #666666 !important;
    transition: .2s;
}    
.darkmode--activated .footer-iiner-page .social-in .dark:hover{
    color: #892CDC !important;
}
.darkmode--activated .footer-iiner-page .logo-otr .logo{
    mix-blend-mode: difference;
} 
.darkmode--activated .footer-iiner-page .right .link{
    color: #666666 !important;
    transition: .2s;
}
.darkmode--activated .footer-iiner-page .right .link:hover{
    color: #892CDC !important;
}
.darkmode--activated .footer-iiner-page .left .copy{
    color: #666666 !important;
}
.darkmode--activated .footer-iiner-page .left .logo-link{
    color: #892CDC !important;
}

//Footer


//======================= Navbar Start Here ========================//

.navbar-inr-page{
    .container{
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px 0 0 0;
            .logo-otr{
                .logo-inr{
                    .logo{
                        width: 180px;
                    }
                }
            }
            .search-input{
                position: relative;
                width: 470px;
                @include breakpoint($secreen-max-lg){
                    display: none;
                }
                .input{
                    width: 100%;
                    padding: 10px 24px;
                    border-radius: 4px;
                    border: none;
                    @include body-s;
                    font-family: $font-family-Rubik-Regular;
                    background-color: $body-color-2;
                    color: $body-text-color;
                    text-transform: capitalize;
                    &::placeholder{
                        color: $body-text-color;
                    }
                    &:focus{
                        border: none;
                        outline: none;
                    }
                }
                .search-icon{
                    position: absolute;
                    top: 50%;
                    right: 24px;
                    transform: translateY(-50%);
                    color: $body-text-color;
                    line-height: 0;
                }
            }
            .mobile-input-main{
                width: 56px;
                height: 56px;
                background-color: $border-color;
                border-radius: 4px;
                position: relative;
                margin-left: 24px;
                transition: .3s;
                &:hover{
                    background-color: $primary-color;
                    .search-iicon{
                        color: black;
                    }
                }
                @include breakpoint($secreen-xl){
                    display: none;
                }
                .search-iicon{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: $white-color;
                    transition: .3s;
                }
            }
            .modal-custom{
                .modal-dialog-custom{
                    .modal-content-custom{
                        background-color: transparent;
                        border: none;
                        .cross-main{
                            margin-left: auto;
                            .cross{
                                color: $white-color;
                                font-size: 20px;
                            }
                        }
                        .input-main{
                            padding: 20px 24px;
                            background-color: $border-color;
                            border-radius: 4px;
                            .mobile-input{
                                width: 100%;
                                padding: 10px 24px;
                                border-radius: 4px;
                                border: none;
                                @include body-s;
                                font-family: $font-family-Rubik-Regular;
                                background-color: $body-color-2;
                                color: $body-text-color;
                                text-transform: capitalize;
                                &::placeholder{
                                    color: $body-text-color;
                                }
                                &:focus{
                                    border: none;
                                    outline: none;
                                }
                            }
                        }
                    }
                }
            }
            .action{
                display: flex;
                @include breakpoint($secreen-max-md){
                    display: none;
                }
                .create-btn{
                    @include site-btn;
                    border: 1px solid transparent;
                }
                .wallet-btn{
                    @include site-btn;
                    background-color: $primary-color;
                    color: $body-color-2;
                    border: 1px solid transparent;
                    &:hover{
                        background-color: $border-color;
                        color: $white-color;
                    }
                }
                .right{
                    margin-left: 24px;
                }
            }
            .circle{
                width: 52px;
                height: 52px;
                border-radius: 100%;
                background-color: $body-color-2;
                position: relative;
                @include breakpoint($secreen-min-lg){
                    display: none;
                }
                .burger{
                    color: $body-text-color;
                    font-size: 20px;
                    position: absolute;
                    top: 53%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}

//======================= Navbar Start End ========================//

//======================= All Artwork Start Here ========================//

.all-artwork{
    padding: 72px 0 96px 0;
    .container{
        .heading{
            color: $white-color;
        }
        .wrapper{
            .nav-pills-custom{
                position: relative;
                margin: 50px 0;
                &::after{
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $border-color;
                }
                .nav-item-custom{
                    &:not(:last-child){
                        margin-right: 32px;
                    }
                    .nav-link-custom{
                        @include body-s;
                        font-family: $font-family-Rubik-Medium;
                        color: $body-text-color;
                        padding: 0 0 16px 0;
                        transition: .3s;
                        background-color: transparent;
                        border: none;
                        &:hover{
                            color: $white-color;
                        }
                    }
                    .active{
                        border-bottom: 1px solid $primary-color;
                        color: $white-color;
                        position: relative;
                        z-index: 1;
                    }
                }
            }
            .tab-content-custom{
                .tab-pane-custom{
                    .row-custom{
                        margin: 50px 0 0 0;
                        @include breakpoint($secreen-max-sm){
                            justify-content: center;
                        }
                        @include breakpoint($secreen-xs){
                            width: 400px;
                            margin: 50px auto 0 auto;
                        }
                        @include breakpoint($secreen-xxs){
                            width: 100%;
                        }
                        .col-otr{
                            .col-inr{
                                &:hover{
                                    cursor: pointer;
                                }
                                .img-otr{
                                    .auction-img{
                                        width: auto;
                                        border-radius: 4px;
                                    }
                                }
                                .art{
                                    padding: 20px 24px 28px 24px;
                                    background-color: $body-color-2;
                                    .abstract{
                                        color: $white-color;
                                        @include body-m;
                                        font-family: $font-family-Rubik-Regular;
                                    }
                                    .user-profile{
                                        display: flex;
                                        align-items: center;
                                        margin: 12px 0 0 0;
                                        .user-img{
                                            width: 32px;
                                            height: 32px;
                                            border-radius: 4px;
                                        }
                                        .username{
                                            @include body-s;
                                            color: $body-text-color;
                                            margin-left: 12px;
                                        }
                                    }
                                }
                                .bit-main-otr{
                                    padding: 16px 24px;
                                    background-color: $border-color;
                                    .bit-inr{
                                        display: flex;
                                        align-items: center;
                                        @include body-s;
                                        color: $body-text-color;
                                        margin: 0 0 4px 0;
                                        @include breakpoint($secreen-lg){
                                            flex-direction: column;
                                            align-items: baseline;
                                        }
                                        .dot{
                                            margin: 0 8px;
                                            @include breakpoint($secreen-lg){
                                                display: none;
                                            }
                                        }
                                        .ETH{
                                            color: $primary-color;
                                        }
                                    }
                                    .ending-main{
                                        display: flex;
                                        align-items: center;
                                        @include body-s;
                                        color: $body-text-color;
                                        @include breakpoint($secreen-lg){
                                            flex-direction: column;
                                            align-items: baseline;
                                            margin: 4px 0 0 0;
                                        }
                                        .dot{
                                            margin: 0 8px;
                                            @include breakpoint($secreen-lg){
                                                display: none;
                                            }
                                        }
                                        .clock-auctions1,.clock-auctions2,.clock-auctions3,.clock-auctions4,.clock-auctions5,.clock-auctions6,.clock-auctions7,.clock-auctions8{
                                            color: $primary-color;
                                        }
                                        .price{
                                            @include body-s;
                                            color: $primary-color;
                                        }
                                    }
                                }
                            }
                            .box1,.box2,.box3,.box4{
                                margin-bottom: 30px;
                            }
                            .box5,.box6{
                                @include breakpoint($secreen-max-md){
                                    margin-bottom: 30px;
                                }
                            }
                            .box7{
                                @include breakpoint($secreen-max-sm){
                                    margin-bottom: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//======================= All Artwork Start Here ========================//