

.darkmode--activated .pages .overlay::after{
    opacity: 1 !important;
}
.darkmode--activated .privacy-main{
    position: relative;
}
.darkmode--activated .privacy-main .para-heading{
    color: black !important;
}
.darkmode--activated .privacy-main .control{
    color: black !important;
}
.darkmode--activated .privacy-main .para{
    color: black !important;
}
.darkmode--activated .privacy-main b{
    color: black !important;
}


//======================= PAGE-HEADING Start Here ========================//

.pages{
    position: relative;
    .overlay{
        &::after{
            content: "";
            position: absolute;
            top:0;
            width: 100%;
            height: 100%;
            background-image: url('/assets/img/sections-bg.jpg');
            background-attachment: fixed;
            background-size: cover;
            opacity: 20%;
        }
        .container{
            position: relative;
            z-index: 10;
            .main{
                text-align: center;
                padding: 64px 0;
                .heading{
                    color: $white-color;
                }
                .content{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include body-m;
                    color: $body-text-color;
                    font-family: $font-family-Rubik-Regular;
                    margin-top: 24px;
                    .home{
                        @include body-m;
                        color: $body-text-color;
                        font-family: $font-family-Rubik-Regular;
                    }
                    .arrow{
                        margin: 0 5px;
                    }
                    .privacy{
                        @include body-m;
                        color: $body-text-color;
                        font-family: $font-family-Rubik-Regular;
                    }
                }
            }
        }
    }
}

//======================= PAGE-HEADING Start End ========================//

//======================= PRIVACY Start Here ========================//

.privacy-main{
    margin: 128px 0;
    .container{
        .privacy-div{
            .first-para{
                .para{
                    color: $white-color;
                    @include body-s;
                }
                .para-2{
                    margin-top: 16px;
                }
                .para-3{
                    margin-top: 16px;
                }
                .para-4{
                    margin-top: 16px;
                }
            }
            .children{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
            .Changes{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
            .Information{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
            .we-use{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
            .we-share{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
            .choices{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .control{
                    color: $white-color;
                    margin-top: 40px;
                    font-size: 24px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
            .accessing{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }  
            }
            .california{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
            .we-protect{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
            .terms{
                .para-heading{
                    color: $white-color;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }
                .para{
                    color: $white-color;
                    margin-top: 16px;
                    @include body-s;
                }
            }
        }
    }
}

//======================= PRIVACY Start End ========================//