//image-zoom

.darkmode--activated .image-zoom{
    background-color: #EEEEEE !important;
    position: relative;
}
.darkmode--activated .image-zoom .icon-outer .icon{
    color: #666666 !important;
}
.darkmode--activated .image-zoom .icon-outer .copy-text{
    background-color: #FFD700 !important;
    color: black !important;
}

//image-zoom

//Hero-same

.darkmode--activated .oldsmobile-main{
    position: relative;
}
.darkmode--activated .oldsmobile-main .heading{
    color: black !important;
}
.darkmode--activated .oldsmobile-main .bit-main{
    color: #666666 !important;
}
.darkmode--activated .oldsmobile-main .bit-main .ETH{
    color: #892CDC !important;
} 
.darkmode--activated .oldsmobile-main .auction-main .auction{
    color: #892CDC !important;
} 
.darkmode--activated .oldsmobile-main .action .buy-btn{
    border: 1px solid #CCCCCC !important;
    background-color: transparent !important;
    color: #666666 !important;
    position: relative !important;
}
.darkmode--activated .oldsmobile-main .action .buy-btn:hover{
    border: 1px solid transparent !important;
    background-color: #892CDC !important;
    color: white !important;
}
.darkmode--activated .oldsmobile-main .action .bit-btn{
    border: 1px solid transparent !important;
    background-color: #FFD700 !important;
    color: #000 !important;
    position: relative !important;
}
.darkmode--activated .oldsmobile-main .action .bit-btn:hover{
    border: 1px solid #892CDC !important;
    background-color: #892CDC !important;
    color: white !important;
}
.darkmode--activated .oldsmobile-main .col-tabs-inr .nav-pills-custom::after{
    background-color: #EEEEEE !important;
}
.darkmode--activated .oldsmobile-main .col-tabs-inr .nav-pills-custom .nav-link-custom{
    color: #666666 !important;
}
.darkmode--activated .oldsmobile-main .col-tabs-inr .nav-pills-custom .active{
    color: #892CDC !important;
    border-bottom: 1px solid #892CDC !important;
}
.darkmode--activated .oldsmobile-main .col-tabs-inr .tab-content-custom .tab-description .desc{
    color: #666666 !important;
}
.darkmode--activated .oldsmobile-main .col-tabs-inr .tab-content-custom .tab-creator .creator-name{
    color: black !important;
}
.darkmode--activated .oldsmobile-main .col-tabs-inr .tab-content-custom .tab-creator .creator-address{
    color: #999999 !important;
}
.darkmode--activated .oldsmobile-main .col-tabs-inr .tab-content-custom .tab-owenr .creator-name{
    color: black !important;
}
.darkmode--activated .oldsmobile-main .col-tabs-inr .tab-content-custom .tab-owenr .creator-address{
    color: #999999 !important;
}
.darkmode--activated .oldsmobile-main .col-tabs-inr .tab-content-custom .tab-history .creator-name{
    color: black !important;
}
.darkmode--activated .oldsmobile-main .col-tabs-inr .tab-content-custom .tab-history .date{
    color: #999999 !important;
}
.darkmode--activated .oldsmobile-main .col-tabs-inr .tab-content-custom .tab-history .price-dolor{
    color: #999999 !important;
}
//Hero-same




//======================= Single Artwork Start Here =======================//

.image-zoom{
    padding: 96px 0;
    background-color: $body-color-2;
    .container{
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .img-otr{
                width: 408px;
                height: 408px;
                @include breakpoint($secreen-xs){
                    width: 100%;
                    height: 100%;
                }
                .modal-img{
                    border-radius: 4px;
                }
            }
            .icon-outer{
                display: flex;
                flex-direction: column;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                .main-icon{
                    position: relative;
                    &:hover{
                        .copy-text{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    &:not(:last-child){
                        margin-bottom: 32px;
                    }
                    .icon{
                        font-size: 20px;
                        color: $body-text-color;
                    }
                    .copy-text{
                        position: absolute;
                        color: $white-color;
                        @include body-s;
                        top: -6px;
                        left: -100px;
                        padding: 4px 12px;
                        border-radius: 4px;
                        background-color: $border-color;
                        opacity: 0;
                        visibility: hidden;
                        transition: .3s;
                    }
                }
            }
            .modal-custom{
                transition: .3s !important;
                .modal-dialog-custom{
                    max-width: 600px !important;
                    transition: .3s;
                    .modal-content-custom{
                        background-color: transparent !important;
                        border: none !important;
                        border-radius: 4px !important;
                        transition: .3s !important;
                        .btn-close{
                            margin-left: auto;
                            color: $body-text-color;
                            opacity: 1;
                            padding: 20px 0;
                            transition: .3s !important;
                        }
                        .modal-img-otr{
                            .modal-img-inr{
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}

//======================= Single Artwork End Here =======================//

//======================= Oldsmobile Start Here =======================//

.oldsmobile-main{
    padding: 72px 0 96px 0;
    .container{
        .row-custom{
            .col-otrr{
                .col-inrr{
                    padding-right: 100px;
                    @include breakpoint($secreen-max-md){
                        padding: 0 0 60px 0;
                        display: flex;
                        flex-direction: column;
                        align-items: baseline;
                    }
                    .user-main{
                        display: flex;
                        align-items: center;
                        padding: 0 0 16px 0;
                        .user-img{
                            width: 32px;
                            border-radius: 4px;
                        }
                        .right{
                            margin-left: 16px;
                        }
                    }
                    .heading{
                        color: $white-color;
                    }
                    .bit-main{
                        display: flex;
                        align-items: center;
                        color: $body-text-color;
                        @include body-s;
                        padding: 16px 0;
                        .dot{
                            margin: 0 5px;
                        }
                        .ETH{
                            color: $primary-color;
                        }
                    }
                    .auction-main{
                        .auction{
                            color: $body-text-color;
                            @include body-s;
                            margin: 0 0 4px 0;
                        }
                        .clock-hero{
                            color: $primary-color;
                            font-size: $font-size-lg;
                            line-height: $line-height-lg;
                            font-family: $font-family-IBMPlexSans-SemiBold;
                            @include breakpoint($secreen-xs){
                                font-size: 32px;
                                line-height: 44px;
                            }
                        }
                    }
                    .action{
                        display: flex;
                        margin: 48px 0 0 0;
                        .buy-btn{
                            @include site-btn;
                            background-color: $primary-color;
                            color: $body-color-2;
                            border: 1px solid transparent;
                            &:hover{
                                background-color: $border-color;
                                color: $white-color;
                            }
                        }
                        .bit-btn{
                            @include site-btn;
                            border: 1px solid transparent;
                        }
                        .right{
                            margin-left: 24px;
                        }
                    }
                }
            }
            .col-tabs-otr{
                .col-tabs-inr{
                    .wrapper{
                        .nav-pills-custom{
                            position: relative;
                            &::after{
                                content: "";
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                height: 1px;
                                background-color: $border-color;
                            }
                            .nav-item-custom{
                                &:not(:last-child){
                                    margin-right: 32px;
                                }
                                .nav-link-custom{
                                    @include body-s;
                                    font-family: $font-family-Rubik-Medium;
                                    color: $body-text-color;
                                    padding: 0 0 16px 0;
                                    transition: .3s;
                                    background-color: transparent;
                                    border: none;
                                    &:hover{
                                        color: $white-color;
                                    }
                                }
                                .active{
                                    border-bottom: 1px solid $primary-color;
                                    color: $white-color;
                                    position: relative;
                                    z-index: 1;
                                }
                            }
                        }
                        .tab-content-custom{
                            .tab-pane-custom{
                                .tab-description{
                                    margin-top: 48px;
                                    .desc{
                                        @include body-s;
                                        color: $body-text-color;
                                    }
                                    .para{
                                        margin-bottom: 16px;
                                    }
                                }
                                .tab-creator{
                                    margin-top: 48px;
                                    .col-otr{
                                        .col-inr{
                                            .featured-link{
                                                display: flex;
                                                align-items: center;
                                                .img-otr{
                                                    width: 52px;
                                                    height: 52px;
                                                    .featured-img{
                                                        border-radius: 4px;
                                                    }
                                                }
                                                .creator-user{
                                                    margin-left: 16px;
                                                    .creator-name{
                                                        color: $white-color;
                                                        @include body-m;
                                                        font-family: $font-family-Rubik-Medium;
                                                    }
                                                    .creator-address{
                                                        @include body-s;
                                                        color: $body-text-color;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .tab-owenr{
                                    margin-top: 48px;
                                    .col-otr{
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        .col-inr{
                                            .featured-link{
                                                display: flex;
                                                align-items: center;
                                                .img-otr{
                                                    width: 52px;
                                                    height: 52px;
                                                    .featured-img{
                                                        border-radius: 4px;
                                                    }
                                                }
                                                .creator-user{
                                                    margin-left: 16px;
                                                    .creator-name{
                                                        color: $white-color;
                                                        @include body-m;
                                                        font-family: $font-family-Rubik-Medium;
                                                    }
                                                    .creator-address{
                                                        @include body-s;
                                                        color: $body-text-color;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .top{
                                        margin-bottom: 30px;
                                    }
                                    .column{
                                        @include breakpoint($secreen-xs){
                                            flex-direction: column;
                                            align-items: baseline;
                                        }
                                    }
                                    .boxxx1,.boxxx3{
                                        @include breakpoint($secreen-xs){
                                            margin-bottom: 30px;
                                        }
                                    }
                                }
                                .tab-history{
                                    margin-top: 48px;
                                    .col-otr{
                                        .col-inr{
                                            .featured-link{
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                .left{
                                                    display: flex;
                                                    align-items: center;
                                                    .img-otr{
                                                        width: 52px;
                                                        height: 52px;
                                                        .featured-img{
                                                            border-radius: 4px;
                                                        }
                                                    }
                                                    .creator-user{
                                                        margin-left: 16px;
                                                        .creator-name{
                                                            color: $white-color;
                                                            @include body-m;
                                                            font-family: $font-family-Rubik-Medium;
                                                            .address{
                                                                @include body-s;
                                                                color: $body-text-color;
                                                            }
                                                        }
                                                        .date{
                                                            @include body-s;
                                                            color: $body-text-color-2;
                                                        }
                                                    }
                                                }
                                                .right{
                                                    text-align: right;
                                                    .price-bit{
                                                        color: $primary-color;
                                                        @include body-s;
                                                    }
                                                    .price-dolor{
                                                        color: $body-text-color-2;
                                                        @include body-s;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .box1,.box2,.box3{
                                        margin-bottom: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//======================= Oldsmobile ENd Here =======================//

//======================= Footer Start Here ========================//

.footer-iiner-page{
    background-color: $body-color-2;
    padding: 40px 0 0 0;
    .container{
        .wrapper{
            .social-media{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                padding: 0 0 40px 0;
                border-bottom: 1px solid $border-color;
                @include breakpoint($secreen-max-md){
                   justify-content: center;
                }
                .social{
                    .social-in{
                        color: $body-text-color;
                        transition: .3s;
                        @include breakpoint($secreen-max-md){
                            margin-right: 60px;
                        }
                        &:hover{
                            color: $white-color;
                        }
                    }
                }
            } 
            .copy-right{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 40px 0;
                @include breakpoint($secreen-max-md){
                    flex-direction: column;
                    justify-content: center;
                }
                .left{
                    display: flex;
                    align-items: center;
                    @include breakpoint($secreen-max-md){
                        margin-bottom: 4px;
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;
                    }
                    .logo-otr{
                        @include breakpoint($secreen-max-md){
                            margin-bottom: 20px;
                        }
                        .logo{
                            width: 180px;
                            margin-right: 24px;
                        }
                    }
                    .copy{
                        @include body-s;
                        color: $body-text-color;
                        .logo-link{
                            color: $primary-color;
                            font-family: $font-family-Rubik-Regular;
                            display: inline;
                        }
                    }
                }
                .right{
                    display: flex;
                    align-items: center;
                    text-align: center;
                    @include breakpoint($secreen-xxs){
                        flex-direction: column
                    }
                    .link{
                        @include body-s;
                        color: $body-text-color;
                        transition: .3s;
                        display: inline;
                        &:hover{
                            color: $white-color;
                        }
                    }
                    .dot{
                        color: $body-text-color;
                        margin: 0 5px;
                        @include breakpoint($secreen-xxs){
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

//======================= Footer End Here ========================//