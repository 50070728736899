html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background-color: $body-color;
    transition: .3s;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

a {
    display: block;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
    padding: 0px;
    color: white !important;
}
p {
    margin: 0px;
    padding: 0px;
}
i {
    cursor: pointer;
}

h1{
    font-size: $font-size-xlg;
    line-height: $line-height-xlg;
    font-family: $font-family-IBMPlexSans-SemiBold;
    @include breakpoint($secreen-xs){
        font-size: 40px;
        line-height: 64px;
    }
}
h2{
    font-size: $font-size-lg;
    line-height: $line-height-lg;
    font-family: $font-family-IBMPlexSans-SemiBold;
    @include breakpoint($secreen-xs){
        font-size: 32px;
        line-height: 44px;
    }
}
h3{
    font-size: $font-size-f-md;
    line-height: $line-height-f-md;
    font-family: $font-family-IBMPlexSans-SemiBold;
    @include breakpoint($secreen-xs){
        font-size: 20px;
        line-height: 36px;
    }
}
h4{
    font-size: $font-size-s-md;
    line-height: $line-height-f-md;
    font-family: $font-family-IBMPlexSans-Medium;
}

br{
    @include breakpoint($secreen-max-md){
        display: none;
    }
}

.fade-scale {
    transform: scale(0);
    opacity: 0;
    transition: all .25s linear;
}
  
.fade-scale.show {
    opacity: 1;
    transform: scale(1);
    transition: all .25s linear;
}