


.preloader {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $body-color;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 20;
  }
  
  .accordeon {
    display: flex;
    position: relative;
    height: 40px;
    justify-content: center;
    align-items: center;
  }
  
  .slice {
    width: 6px;
    height: 20px;
    border-radius: 100px;
    display: inline-block;
    margin: 4px;
  }
  .slice1,.slice3{
    background-color: $primary-color;
  }
  .slice2,.slice4{
    background-color: $white-color;
  }
  
  @keyframes SliceHeight {
    100% {
      height: 20px;
    }
  
    50% {
      height: 60px;
    }
  }

.overlay-menuu{
    height: 100%;
    width: 0%;
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    background-color: $body-color;
    overflow-y: hidden;
    transition: 0.2s;
    .close-cricle{
        position: relative;
        .circle-inr{
            width: 52px;
            height: 52px;
            border-radius: 100%;
            background-color: $body-color-2;
            position: absolute;
            right: 24px;
            top: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            .close{
                color: #B3B3D5;
                font-size: 20px;
                transform: rotate(-45deg);
                position: relative;
                top: 1px;
                left: 1px;
            }
        }
    }
    .overlay-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        .overlay-ul{
            display: flex;
            align-items: center;
            flex-direction: column;
            .overlay-li{
                position: relative;
                &:not(:last-child){
                    margin-bottom: 12px;
                }
                .overlay-a{
                    color: $white-color;
                    @include body-l;
                    font-family: $font-family-Rubik-Medium;
                    transition: .3s;
                    &:hover{
                        color: $primary-color;
                    }
                }
            }
        }
        .action-otr{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 32px;
            .action-inr{
                @include site-btn-secondry;
            }
        }
    }
}

//======================= Navbar Start Here ========================//

.navbar-start{
    .container{
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px 0;
            .logo-otr{
                .logo-inr{
                    .logo{
                        width: 180px;
                    }
                }
            }
            .navigation{
                .nav-otr{
                    display: flex;
                    align-items: center;
                    .nav-inr{
                        position: relative;
                        &:not(:last-child){
                            margin-right: 40px;
                        }
                        .navv-link{
                            color: $white-color;
                            @include body-s;
                            font-family: $font-family-Rubik-Medium;
                            transition: .3s;
                            &:hover{
                                color: $primary-color;
                            }
                        }
                    }
                }
                @include breakpoint($secreen-max-md){
                    display: none;
                }
            }
            .action-otr{
                display: flex;
                @include breakpoint($secreen-max-md){
                    display: none;
                }
                .action-inr{
                    @include site-btn-secondry;
                }
            }
            .circle{
                width: 52px;
                height: 52px;
                border-radius: 100%;
                background-color: $body-color-2;
                position: relative;
                @include breakpoint($secreen-min-lg){
                    display: none;
                }
                .burger{
                    color: $body-text-color;
                    font-size: 20px;
                    position: absolute;
                    top: 53%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}

//======================= Navbar Start End ========================//

//======================= Hero Start Here ========================//

.hero-section{
    background-color: $body-color-2;
    position: relative;
    .overlay{
        &::after{
            content: "";
            position: absolute;
            top:0;
            width: 100%;
            height: 100%;
            background-image: url('/assets/img/sections-bg.jpg');
            background-attachment: fixed;
            background-size: cover;
            opacity: 20%;
        }
        .container{
            position: relative;
            z-index: 1;
            .row-custom{
                align-items: center;
                padding: 72px 0;
                .col-otr{
                    .col-inr{
                        padding-right: 113px;
                        @include breakpoint($secreen-max-md){
                            text-align: center;
                            padding-right: 0;
                        }
                        @include breakpoint($secreen-max-lg){
                            padding-right: 0px;
                        }
                        .heading{
                            color: $white-color;
                            .payemnt{
                                color: $primary-color;
                                font-style: italic;
                            }
                        }
                        .desc{
                            margin: 34px 0;
                            color: $white-color;
                            @include body-l;
                        }
                        .action-otr{
                            display: flex;
                            @include breakpoint($secreen-max-md){
                                justify-content: center;
                                margin-bottom: 100px;
                            }
                            .action-inr{
                                @include site-btn-primary;
                            }
                        }
                    }
                }
                .col-img-otr{
                    .col-img-inr{
                        position: relative;
                        .hero-image{
                            width: auto;
                            @include animation-1;
                            animation: animation-1 5s linear infinite;
                        }
                    }
                }
            }
        }
    }
}
//======================= Hero End Here ========================//

//======================= Clients Start Here ========================//

.clients{
    .container{
        .wrapper-desktop{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 48px;
            @include breakpoint($secreen-max-md){
                display: none;
            }
            @include breakpoint($secreen-xs){
                display: flex;
                flex-direction: column;
            }
            .clients-img-otr{
                @include breakpoint($secreen-xs){
                    &:not(:last-child){
                        margin-bottom: 30px;
                    }
                }
                .clients-img{
                    &:hover{
                        path{
                            fill: $white-color;
                        }
                    }
                    path{
                        fill: $body-text-color-2;
                        transition: .5s;
                    }
                }
            }
        }
        .wrapper-tablet{
            margin-top: 128px;
            @include breakpoint($secreen-min-lg){
                display: none;
            }
            @include breakpoint($secreen-xs){
                display: none;
            }
            .clients-first{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .clients-img-otr{
                    .clients-img{
                        &:hover{
                            path{
                                fill: $white-color;
                            }
                        }
                        path{
                            fill: $body-text-color-2;
                            transition: .5s;
                        }
                    }
                }
            }
            .clients-second{
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                margin-top: 60px;
                .clients-img-otr{
                    .clients-img{
                        &:hover{
                            path{
                                fill: $white-color;
                            }
                        }
                        path{
                            fill: $body-text-color-2;
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }
}

//======================= Clients End Here ========================//


//======================= Built In Start Here ========================//

.built-in{
    margin: 128px 0;
    .container{
        .wrapper{
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 0 128px 0;
            .heading{
                color: $white-color;
            }
            .desc{
                color: $body-text-color;
                @include body-m;
                font-family: $font-family-Rubik-Regular;
                padding: 24px 0 32px 0;
            }
            .action-otr{
                display: flex;
                .action-inr{
                    @include site-btn-secondry;
                }
            }
        }
        .row-custom{
            .col-otr{
                .col-inr{
                    padding-right: 80px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    @include breakpoint($secreen-max-md){
                        justify-content: center;
                        align-items: center;
                        padding-right: 0;
                        text-align: center;
                    }
                    .content{
                        .col-heading{
                            color: $white-color;
                        }
                        .col-desc{
                            padding: 24px 0 0 0;
                            color: $body-text-color;
                            @include body-m;
                            font-family: $font-family-Rubik-Regular;
                            @include breakpoint($secreen-max-md){
                                padding: 24px 0 60px 0;
                            }
                        }
                    }
                    .img-otr{
                        overflow: hidden;
                        position: relative;
                        .everywhere{
                            width: auto;
                        }
                        .overlay-box{
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            padding: 20px 32px;
                            border-radius: 0px 0px 4px 4px;
                            background-color: $primary-color;
                            .ab-head{
                                color: $body-color-2;
                            }
                            .sold{
                                @include body-s;
                                font-family: $font-family-Rubik-Regular;
                                color: $body-color-2;
                                margin-top: 8px;
                            }
                        }
                    }
                }
            }
            .col-boxes-otr{
                .col-boxes-inr{
                    @include breakpoint($secreen-max-md){
                        margin: 80px 0 0 0;
                    }
                    .box{
                        padding: 40px;
                        background: linear-gradient(89.69deg, #000272 0.25%, #1E2090 99.73%);
                        transition: .5s;
                        border-radius: 4px;
                        position: relative;
                        &:hover::after{
                            transition: .5s;
                            opacity: 1;
                        }
                        &::after{
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: 4px;
                            background: linear-gradient(89.69deg, #000272 100%, #1E2090 99.73%);
                            transition: .5s;
                            opacity: 0;
                        }
                        .box-inr{
                            position: relative;
                            z-index: 10;
                            .box-title{
                                display: flex;
                                align-items: center;
                                .icon{
                                    font-size: 40px;
                                    color: $primary-color;
                                    margin-right: 24px;
                                }
                                .box-heading{
                                    color: $white-color;
                                }
                            }
                            .box-desc{
                                color: $body-text-color;
                                @include body-s;
                                font-family: $font-family-Rubik-Regular;
                                margin: 24px 0 0 0;
                            } 
                        }  
                    }
                    .box-1,.box-2{
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}

//======================= Built In End Here ========================//

//=======================  Get Started Start Here ========================//

.get-started{
    position: relative;
    padding: 128px 0;
    margin: 128px 0;
    background-color: $body-color-2;
    .overlay-get{
        &::after{
            content: "";
            position: absolute;
            top:0;
            width: 100%;
            height: 100%;
            background-image: url('/assets/img/sections-bg.jpg');
            background-attachment: fixed;
            background-size: cover;
            opacity: 20%;
        }
        .container{
            .wrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                position: relative;
                z-index: 10;
                .heading{
                    color: $white-color;
                }
                .desc{
                    @include body-m;
                    color: $body-text-color;
                    font-family: $font-family-Rubik-Regular;
                    padding: 24px 0 72px 0;
                }
                .input-main{
                    width: 570px;
                    margin: 0 auto;
                    @include breakpoint($secreen-max-sm){
                        width: 100%;
                    }
                    .input{
                        width: 100%;
                        padding: 10px 24px;
                        border-radius: 4px;
                        border: none;
                        @include body-s;
                        font-family: $font-family-Rubik-Regular;
                        background-color: $white-color;
                        color: $body-text-color;
                        text-transform: capitalize;
                        &::placeholder{
                            color: $body-text-color;
                        }
                        &:focus{
                            border: none;
                            outline: none;
                        }
                    } 
                    .action-otr{
                        display: flex;
                        margin-top: 30px;
                        .action-inr{
                            @include site-btn-primary;
                            width: 100%;
                            justify-content: center;
                        }
                    }  
                }
            }
        }
    }
}

//=======================  Get Started End Here ========================//

//=======================  Limited Supply Start Here ========================//

.limited-supply{
    margin: 128px 0;
    .container{
        .row-custom{
            align-items: center;
            .col-otr{
                .col-inr{
                    padding: 36px 40px 40px 40px;
                    background: linear-gradient(89.69deg, #000272 0.25%, #1E2090 99.73%);
                    transition: .5s;
                    position: relative;
                    border-radius: 4px;
                    margin-right: 100px;
                    &:hover::after{
                        transition: .5s;
                        opacity: 1;
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(89.69deg, #000272 100%, #1E2090 99.73%);
                        transition: .5s;
                        opacity: 0;
                        border-radius: 4px;
                    }
                    @include breakpoint($secreen-max-md){
                        margin-bottom: 30px;
                        margin-right: 0px;
                        padding: 32px;
                    }
                    .col-iner-main{
                        position: relative;
                        z-index: 10;
                        .col-heading{
                            color: $white-color;
                        }
                        .col-desc{
                            @include body-m;
                            font-family: $font-family-Rubik-Regular;
                            color: $body-text-color;
                            padding: 20px 0 40px 0;
                        }
                        .action-otr{
                            display: flex;
                            .action-inr{
                                @include site-btn-primary;
                            }
                        }
                    }
                }
            }
            .col-boxes-otr{
                .col-boxes-inr{
                    .box{
                        padding: 40px;
                        background: linear-gradient(89.69deg, #000272 0.25%, #1E2090 99.73%);
                        transition: .5s;
                        border-radius: 4px;
                        position: relative;
                        @include breakpoint($secreen-max-md){
                            padding: 32px;
                        }
                        &:hover::after{
                            transition: .5s;
                            opacity: 1;
                        }
                        &::after{
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: 4px;
                            background: linear-gradient(89.69deg, #000272 100%, #1E2090 99.73%);
                            transition: .5s;
                            opacity: 0;
                        }
                        .box-inr{
                            position: relative;
                            z-index: 10;
                            .supply{
                                @include body-s;
                                color: $primary-color;
                                font-family: $font-family-Rubik-Regular;
                            }
                            .numbers{
                                color: $white-color;
                                margin: 8px 0 0 0;
                            }
                        }  
                    }
                    .box-1,.box-2{
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}

//=======================  Limited Supply End Here ========================//

//=======================  Charts Start Here ========================//

.charts-custom{
    margin: 128px 0;
    .overlay{
        position: relative;
        padding: 128px 0;
        &::after{
            content: "";
            position: absolute;
            top:0;
            width: 100%;
            height: 100%;
            background-image: url('/assets/img/sections-bg.jpg');
            background-attachment: fixed;
            background-size: cover;
            opacity: 20%;
        }
        .container{
            position: relative;
            z-index: 10;
            .row-custom{
                .col-otr{
                    .col-inr{
                        padding: 36px 40px 40px 40px;
                        background: linear-gradient(89.69deg, #000272 0.25%, #1E2090 99.73%);
                        transition: .5s;
                        position: relative;
                        border-radius: 4px;
                        &:hover::after{
                            transition: .5s;
                            opacity: 1;
                        }
                        &::after{
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(89.69deg, #000272 100%, #1E2090 99.73%);
                            transition: .5s;
                            opacity: 0;
                            border-radius: 4px;
                        }
                        .charts-main{
                            position: relative;
                            z-index: 10;
                            .heading{
                                color: $white-color;
                                margin: 0 0 40px 0;
                            }
                            #myChart-1,
                            #myChart-2{
                                width: 400px !important;
                                height: 400px !important;
                                margin: 0 auto;
                                @include breakpoint($secreen-max-md){
                                    width: 100% !important;
                                    height: 100% !important;
                                }
                            }
                            .chart-ul{
                                display: flex;
                                flex-wrap: wrap;
                                margin-top: 50px;
                                .chart-li{
                                    display: flex;
                                    align-items: center;
                                    margin-top: 8px;
                                    &:not(:last-child){
                                        margin-right: 24px;
                                    }
                                    .chart-box{
                                        width: 12px;
                                        height: 12px;
                                        border-radius: 4px;
                                        margin-right: 12px
                                    }
                                    .chart-lable{
                                        @include body-s;
                                        font-family: $font-family-Rubik-Regular;
                                        color: $body-text-color;
                                    }
                                    .box1{
                                        background-color: #F6D272;
                                    }
                                    .box2{
                                        background-color: #738FCD;
                                    }
                                    .box3{
                                        background-color: #4DC8BD;
                                    }
                                    .box4{
                                        background-color: #EE6D7B;
                                    }
                                    .box5{
                                        background-color: #FFD700;
                                    }
                                }
                            }
                        }
                    }
                    .boxx1{
                        @include breakpoint($secreen-max-md){
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }
    }
}

//=======================  Charts Start Here ========================//

//=======================  Globle payments Start Here ========================//

.globle-payment{
    position: relative;
    margin: 228px 0 128px 0;
    height: 650px;
    @include breakpoint($secreen-max-md){
        margin: 128px 0;
        height: auto;
    }
    .container{
        .row-custom{
            position: relative;
            .col-otr{
                .col-inr{
                    position: relative;
                    z-index: 10;
                    padding-right: 70px;
                    @include breakpoint($secreen-max-md){
                        text-align: center;
                        padding-right: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                    .heading{
                        color: $white-color;
                    }
                    .desc{
                        @include body-m;
                        font-family: $font-family-Rubik-Regular;
                        color: $body-text-color;
                        padding: 24px 0  40px 0;
                    }
                    .action-otr{
                        display: flex;
                        .action-inr{
                            @include site-btn-secondry;
                        }
                    }
                }
            }
            .col-img-otr{
                .col-img-inr{
                    position: relative;
                    @include breakpoint($secreen-max-md){
                        overflow: hidden;
                    }
                    .mission-2{
                        width: 400px;
                        position: relative;
                        z-index: 1;
                        top: 300px;
                        left: -120px;
                        @include breakpoint($secreen-max-md){
                            display: none;
                        }
                        @include breakpoint($secreen-max-lg){
                            width: 350px;
                            top: 330px;
                        }
                    }
                    .mission-tablet{
                        @include breakpoint($secreen-max-md){
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            left: 115px;
                            margin-top: 80px;
                        }
                        @include breakpoint($secreen-min-lg){
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .mission-1{
        width: 500px;
        position: absolute;
        right: 0;
        top: -100px;
        @include breakpoint($secreen-max-md){
            display: none;
        }
    }
    .earth{
        position: absolute;
        left: 0;
        top: -100px;
        @include breakpoint($secreen-xs){
            display: none;
        }
    }
}

//=======================  Globle payments End Here ========================//

//=======================  Blog Posts Start Here ========================//

.blog-main{
    margin: 128px 0;
    .container{
        .wrapper{
            text-align: center;
            margin-bottom: 72px;
            .heading{
                color: $white-color;
                margin-bottom: 24px;
            }
            .desc{
                @include body-m;
                color: $body-text-color;
                font-family: $font-family-Rubik-Regular;
            }
        }
        .row-custom{
            .col-otr{
                .col-inr{
                    padding: 32px;
                    background: linear-gradient(89.69deg, #000272 0.25%, #1E2090 99.73%);
                    transition: .5s;
                    position: relative;
                    border-radius: 4px;
                    &:hover::after{
                        transition: .5s;
                        opacity: 1;
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(89.69deg, #000272 100%, #1E2090 99.73%);
                        transition: .5s;
                        opacity: 0;
                        border-radius: 4px;
                    }
                    .blog{
                        position: relative;
                        z-index: 10;
                        display: flex;
                        align-items: center;
                        @include breakpoint($secreen-xs){
                            flex-direction: column;
                            align-items: baseline;
                        }
                        .img-otr{
                            .img-inr{
                                width: 156px;
                                height: 156px;
                                border-radius: 4px;
                                margin-right: 32px;
                                @include breakpoint($secreen-xs){
                                    margin-right: 0;
                                    margin-bottom: 32px;
                                }
                            }
                        }
                        .content{
                            .blog-heading{
                                font-size: $font-size-s-md;
                                line-height: $line-height-f-md;
                                font-family: $font-family-IBMPlexSans-Medium;
                                color: $white-color;
                                transition: .3s;
                                margin-bottom: 8px;
                                &:hover{
                                    color: $primary-color;
                                    text-decoration: underline;
                                }
                            }
                            .crypto{
                                color: $primary-color;
                                @include body-s;
                                font-family: $font-family-Rubik-Regular;
                                .date{
                                    color: $body-text-color;
                                }
                            }
                        }
                    }
                }
                .box1,.box2{
                    margin-bottom: 30px;
                }
                .box3{
                    @include breakpoint($secreen-max-md){
                        margin-bottom: 30px;
                    }
                }
            }
        }
        .action-otr{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 72px;
            .action-inr{
                @include site-btn-secondry;
            }
        }
    }
}

//=======================  Blog Posts End Here ========================//

//=======================  Video Section Start Here ========================//

.learn-more{
    margin: 128px 0;
    background-color: $body-color-2;
    .overlay{
        position: relative;
        padding: 128px 0;
        &::after{
            content: "";
            position: absolute;
            top:0;
            width: 100%;
            height: 100%;
            background-image: url('/assets/img/sections-bg.jpg');
            background-attachment: fixed;
            background-size: cover;
            opacity: 20%;
        }
        .container{
            position: relative;
            z-index: 10;
            .wrapper{
                margin-bottom: 72px;
                .heading{
                    color: $white-color;
                    margin-bottom: 24px;
                }
                .desc{
                    @include body-m;
                    color: $body-text-color;
                    font-family: $font-family-Rubik-Regular;
                }
            }
            .col-otr{
                .col-inr{
                    position: relative;
                    width: 100%;
                    .img-otr{
                        position: relative;
                        border: 20px solid $primary-color;
                        border-radius: 4px;
                        &::after{
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: $body-text-color-2;
                            opacity: 0.6;
                        }
                        .video-img{
                            border-radius: 0px;
                        }
                    }
                    .play-icon-otr{
                        width: 80px;
                        height: 80px;
                        background-color: $primary-color;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        .play-icon{
                            color: black;
                            font-size: 24px;
                            position: relative;
                            top: 3px;
                        }
                    }
                }
            }
        }
    }
}

//=======================  Video Section End Here ========================//

//=======================  Road Map Start Here ========================//

.road-map{
    margin: 128px 0;
    .container{
        .wrapper{
            text-align: center;
            margin-bottom: 72px;
            .heading{
                color: $white-color;
                margin-bottom: 24px;
            }
            .desc{
                @include body-m;
                color: $body-text-color-2;
                font-family: $font-family-Rubik-Regular;
            }
        }
        .row-custom-main{
            position: relative;
            margin: 0 !important;
            &::after{
                content: "";
                position: absolute;
                width: 1px;
                height: 85%;
                background-color: #3234A4;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @include breakpoint($secreen-max-md){
                    top: 50%;
                    left: 0px;
                    height: 100%;
                }
            }
            .row-custom1{
                align-items: center;
                position: relative;
                @include breakpoint($secreen-max-md){
                    flex-direction: column-reverse;
                    margin: 0 0 30px 0;
                }
                &::after{
                    content: "";
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    background-color: $primary-color;
                    border-radius: 100%;
                    top: 50%;
                    left: 50.9%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                    @include breakpoint($secreen-max-md){
                        width: 15px;
                        height: 15px;
                        left: 0;
                        top: 17px;
                    }
                }
                &::before{
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background-color: $body-color-2;
                    border-radius: 100%;
                    top: 50%;
                    left: 50.9%;
                    transform: translate(-50%, -50%);
                    z-index: 2;
                    @include breakpoint($secreen-max-md){
                        width: 8px;
                        height: 8px;
                        left: 0;
                        top: 17px;
                    }
                }
                .col-otr1{
                    .col-inr1{
                        text-align: right;
                        padding: 28px 40px;
                        background-color: $body-color-2;
                        border-radius: 4px;
                        margin-right: 30px;
                        background: linear-gradient(89.69deg, #1E2090 0.25%, #000272 99.73%);
                        transition: .5s;
                        position: relative;
                        @include breakpoint($secreen-max-md){
                            text-align: left;
                            background: $body-color-2;
                            margin: 0;
                        }
                        &:hover::after{
                            transition: .5s;
                            opacity: 1;
                        }
                        &::after{
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(89.69deg, #1E2090 -448%, #000272 99.73%);
                            transition: .5s;
                            opacity: 0;
                            border-radius: 4px;
                            @include breakpoint($secreen-max-md){
                                display: none;
                            }
                        }
                        .col-innner-main1{
                            position: relative;
                            z-index: 10;
                            .col-heading1{
                                color: $white-color;
                            }
                            .col-desc1{
                                @include body-s;
                                color: $body-text-color-2;
                                font-family: $font-family-Rubik-Regular;
                                margin-top: 16px;
                            }
                        }
                    }
                }
                .col-date-otr1{
                    .col-date-inr1{
                        margin-left: 60px;
                        @include breakpoint($secreen-max-md){
                            margin-left: 0;
                        }
                        .date1{
                            font-size: $font-size-f-md;
                            line-height: $line-height-f-md;
                            font-family: $font-family-IBMPlexSans-SemiBold;
                            color: $body-text-color-2;
                            margin: 0 0 24px 0;
                        }
                    }
                }
            }
            .row-custom2{
                align-items: center;
                position: relative;
                @include breakpoint($secreen-max-md){
                    margin: 0 0 30px 0;
                }
                &::after{
                    content: "";
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    background-color: $primary-color;
                    border-radius: 100%;
                    top: 50%;
                    left: 50.9%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                    @include breakpoint($secreen-max-md){
                        width: 15px;
                        height: 15px;
                        left: 0;
                        top: 17px;
                    }
                }
                &::before{
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background-color: $body-color-2;
                    border-radius: 100%;
                    top: 50%;
                    left: 50.9%;
                    transform: translate(-50%, -50%);
                    z-index: 2;
                    @include breakpoint($secreen-max-md){
                        width: 8px;
                        height: 8px;
                        left: 0;
                        top: 17px;
                    }
                }
                .col-otr2{
                    .col-inr2{
                        text-align: right;
                        padding: 28px 40px;
                        background-color: $body-color-2;
                        border-radius: 4px;
                        margin-left: 60px;
                        background: linear-gradient(89.69deg, #000272 0.25%, #1E2090 99.73%);
                        transition: .5s;
                        position: relative;
                        @include breakpoint($secreen-max-md){
                            background: $body-color-2;
                            margin: 0;
                        }
                        &:hover::after{
                            transition: .5s;
                            opacity: 1;
                        }
                        &::after{
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(89.69deg, #000272 100%, #1E2090 99.73%);
                            transition: .5s;
                            opacity: 0;
                            border-radius: 4px;
                        }
                        .col-innner-main2{
                            position: relative;
                            z-index: 10;
                            text-align: left;
                            .col-heading2{
                                color: $white-color;
                            }
                            .col-desc2{
                                @include body-s;
                                color: $body-text-color-2;
                                font-family: $font-family-Rubik-Regular;
                                margin-top: 16px;
                            }
                        }
                    }
                }
                .col-date-otr2{
                    .col-date-inr2{
                        margin-left: 30px;
                        margin-right: 30px;
                        @include breakpoint($secreen-max-md){
                            margin: 0;   
                        }
                        .date2{
                            font-size: $font-size-f-md;
                            line-height: $line-height-f-md;
                            font-family: $font-family-IBMPlexSans-SemiBold;
                            color: $body-text-color-2;
                            text-align: right;
                            
                            @include breakpoint($secreen-max-md){
                                text-align: left;
                                margin: 0 0 24px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

//=======================  Road Map End Here ========================//

//=======================  TEAM-SECTION Start Here ========================//

.team{
    margin: 128px 0;
    .container{
        .wrapper{
            text-align: center;
            margin-bottom: 72px;
            .heading{
                color: $white-color;
                margin-bottom: 24px;
                .dash{
                    color: $primary-color;
                }
            }
            .desc{
                @include body-m;
                color: $body-text-color-2;
                font-family: $font-family-Rubik-Regular;
            }
        }
        .team-main{
            .box{
                .col-inner{
                    overflow: hidden;
                    .col-img{
                        overflow: hidden;
                        transition: .3s;
                        width: 100%;
                    }
                    .conetnt-div{
                        text-align: center;
                        background-color: $border-color;
                        margin-top: 20px;
                        padding: 16px 0;
                        height: 68px;
                        overflow: hidden;
                        position: relative;
                        &:hover{
                            .lead{
                                transform: translateY(-33px);
                                opacity: 1;
                            }
                            .team-name{
                                transform: translateY(-40px);
                                opacity: 0;
                            }
                        }
                    }
                    .team-name{
                        color: $white-color;
                        transition: .3s;
                    }
                    .lead{
                        color: $white-color;
                        @include body-s;
                        font-family: $font-family-Rubik-Regular;
                        transform: translateY(10px);
                        opacity: 1;
                        transition: .3s;
                    }
                }
                .box1,.box2{
                    @include breakpoint($secreen-max-md){
                        margin-bottom: 30px;
                    }
                }
                .box3{
                    @include breakpoint($secreen-max-sm){
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}

//=======================  TEAM-SECTION End Here ========================//

//=======================  Faqs Section Start Here ========================//

.faq-main{
    margin: 128px 0;
    .overlay{
        padding: 128px 0;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            top:0;
            width: 100%;
            height: 100%;
            background-image: url('/assets/img/sections-bg.jpg');
            background-attachment: fixed;
            background-size: cover;
            opacity: 20%;
        }
    }
    .container{
        position: relative;
        z-index: 10;
        .wrapper{
            text-align: center;
            margin-bottom: 72px;
            .heading{
                color: $white-color;
                margin-bottom: 24px;
            }
            .desc{
                @include body-m;
                color: $body-text-color-2;
                font-family: $font-family-Rubik-Regular;
            }
        }
        .row-custom{
            justify-content: center;
            .col-otr{
                .col-inr{
                    .collap-otr{
                        .collap-inr{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            padding: 16px 24px;
                            background-color: $body-color-2;
                            border-radius: 4px;
                            border: none;
                            .collap-text{
                                @include body-s;
                                color: $white-color;
                                font-family: $font-family-Rubik-Regular;
                            }
                            .down-arrow{
                                font-size: 20px;
                                color: $white-color;
                                transition: .3s;
                            }
                        }
                    }
                }
                .box1,.box2,.box3,.box4,.box5,.box6{
                    margin-bottom: 30px;
                }
            }
        }
    }
}

//=======================  Faqs Section End Here ========================//

//=======================  Carrers Start Here ========================//

.darkmode--activated .careers-main{
    position: relative;
}
.darkmode--activated .careers-main .wrapper .heading{
    color: black !important;
}
.darkmode--activated .careers-main .wrapper .desc{
    color: black !important;
}
.darkmode--activated .careers-main .col-inr{
    background: #EEEEEE  !important;
}
.darkmode--activated .careers-main .col-inr::after{
    background: #EEEEEE !important;
}
.darkmode--activated .careers-main .col-inr .box-heading{
    color: black !important;
}
.darkmode--activated .careers-main .col-inr .action-otr .action-inr{
    border: 1px solid black !important;
}
.darkmode--activated .careers-main .col-inr .action-otr .action-inr:hover{
    border: 1px solid #892CDC !important;
    background-color: #892CDC !important;
}
.darkmode--activated .careers-main .col-inr .action-otr .action-inr:hover .btn-text{
    color: white !important;
}
.darkmode--activated .careers-main .col-inr .action-otr .action-inr:hover .right-arrow{
    color: white !important;
}
.darkmode--activated .careers-main .col-inr .action-otr .action-inr .btn-text{
    color: black !important;
}
.darkmode--activated .careers-main .col-inr .action-otr .action-inr .right-arrow{
    color: black !important;
}



.careers-main{
    margin: 128px 0;
    .container{
        .wrapper{
            text-align: center;
            margin-bottom: 72px;
            .heading{
                color: $white-color;
                margin-bottom: 24px;
                .dash{
                    color: $primary-color;
                }
            }
            .desc{
                @include body-m;
                color: $body-text-color-2;
                font-family: $font-family-Rubik-Regular;
            }
        }
        .row-custom{
            .col-otr{
                .col-inr{
                    padding: 28px 40px;
                    border-radius: 4px;
                    background: linear-gradient(89.69deg, #000272 0.25%, #1E2090 99.73%);
                    transition: .5s;
                    position: relative;
                    overflow: hidden;
                    &:hover::after{
                        transition: .5s;
                        opacity: 1;
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(89.69deg, #000272 100%, #1E2090 99.73%);
                        transition: .5s;
                        opacity: 0;
                        border-radius: 4px;
                    }
                    .modal-btn{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        position: relative;
                        z-index: 10;
                        @include breakpoint($secreen-max-sm){
                            flex-direction: column;
                            justify-content: center;
                        }
                        .box-heading{
                            color: $white-color;
                            @include breakpoint($secreen-max-sm){
                                margin-bottom: 24px;
                            }
                        }
                        .action-otr{
                            display: flex;
                            .action-inr{
                                @include site-btn-secondry;
                                border: 1px solid $primary-color;
                                background-color: transparent;
                                &:hover{
                                    background-color: $primary-color;
                                    border: 1px solid transparent;
                                    .btn-text{
                                        color: black;
                                    }
                                    .right-arrow{
                                        color: black;
                                    }
                                }
                            }
                        }
                    }
                    .modal-custom{
                        overflow-y: inherit !important;
                        .modal-dialog-custom{
                            max-width: 770px !important;
                            height: 600px !important;
                            overflow-y: scroll;
                            &::-webkit-scrollbar{
                                width: 5px;
                                background-color: $border-color;
                                border-radius: 4px;
                            }
                            &::-webkit-scrollbar-thumb{
                                background-color: $body-color-2;
                                border-radius: 4px;
                            }
                            .modal-content-custom{
                                padding: 48px 56px;
                                border-radius: 4px !important;
                                background-color: $border-color;
                                .modaal-header{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    .modaal-heading{
                                        color: $white-color;
                                    }
                                    .close-icon{
                                        .close{
                                            color: $body-text-color;
                                            font-size: 16px;
                                        }
                                    }
                                }
                                .location-otr{
                                    display: flex;
                                    align-items: center;
                                    padding: 16px 0 32px 0;
                                    .world{
                                        color: $body-text-color;
                                        @include body-s;
                                        font-family: $font-family-Rubik-Regular;
                                        .location{
                                            color: $white-color;
                                        }
                                    }
                                    .right{
                                        margin-left: 52px;
                                    }
                                }
                                .nfdto-info{
                                    .nfdto-head{
                                        color: $white-color;
                                    }
                                    .nfdto-desc{
                                        margin: 16px 0;
                                        @include body-s;
                                        font-family: $font-family-Rubik-Regular;
                                        color: $body-text-color;
                                    }
                                    .last{
                                        margin: 0;
                                    }
                                }
                                .responsibilities{
                                    margin: 40px 0 0 0;
                                    .responsibilities-heading{
                                        color: $white-color;
                                        margin: 0 0 20px 0;
                                    }
                                    .responsibilities-ul{
                                        .responsibilities-li{
                                            display: flex;
                                            &:not(:last-child){
                                                margin-bottom: 8px;
                                            }
                                            .otr-circle{
                                                width: 20px;
                                                height: 20px;
                                                background-color: $primary-color;
                                                border-radius: 100%;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                position: relative;
                                                top: 6px;
                                                margin-right: 12px;
                                                .inr-circle{
                                                    width: 12px;
                                                    height: 12px;
                                                    background-color: $border-color;
                                                    border-radius: 100%;
                                                    position: relative;
                                                    z-index: 1;
                                                    display: flex;
                                                }
                                            }
                                            .responsibilities-para{
                                                @include body-s;
                                                color: $body-text-color;
                                                font-family: $font-family-Rubik-Regular;
                                            }
                                        }
                                    }
                                }
                                .skills{
                                    margin: 40px 0 0 0;
                                    .skills-heading{
                                        color: $white-color;
                                        margin: 0 0 20px 0;
                                    }
                                    .skills-ul{
                                        .skills-li{
                                            display: flex;
                                            &:not(:last-child){
                                                margin-bottom: 8px;
                                            }
                                            .otr-circle{
                                                width: 20px;
                                                height: 20px;
                                                background-color: $primary-color;
                                                border-radius: 100%;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                position: relative;
                                                top: 6px;
                                                margin-right: 12px;
                                                .inr-circle{
                                                    width: 12px;
                                                    height: 12px;
                                                    background-color: $border-color;
                                                    border-radius: 100%;
                                                    position: relative;
                                                    z-index: 1;
                                                    display: flex;
                                                }
                                            }
                                            .skills-para{
                                                @include body-s;
                                                color: $body-text-color;
                                                font-family: $font-family-Rubik-Regular;
                                            }
                                        }
                                    }
                                }
                                .paragraph{
                                    color: $body-text-color;
                                    @include body-s;
                                    font-family: $font-family-Rubik-Regular;
                                    text-align: justify;
                                    padding: 16px 0 40px 0;
                                }
                                .upload-main{
                                    padding: 32px 40px;
                                    border-radius: 4px;
                                    background-color: $body-color-2;
                                    text-align: center;
                                    .upload-head{
                                        color: $white-color;
                                        margin-bottom: 8px;
                                    }
                                    .format{
                                        @include body-s;
                                        color: $body-text-color;
                                        font-family: $font-family-Rubik-Regular;
                                    }
                                    .lable-main{
                                        padding: 40px;
                                        width: 100%;
                                        border-radius: 4px;
                                        border: 1px solid $border-color;
                                        margin: 24px 0 0 0;
                                        .file-input{
                                            display: none;
                                        }
                                        .doc-icon{
                                            font-size: 40px;
                                            color: $primary-color;
                                            margin-bottom: 16px;
                                        }
                                        .drag{
                                            color: $body-text-color;
                                            @include body-s;
                                            font-family: $font-family-Rubik-Regular;
                                            .browse{
                                                color: $primary-color;
                                            }
                                        }
                                    }
                                }
                                .action-otr{
                                    display: flex;
                                    margin: 40px 0 0 0;
                                    .action-inr{
                                        @include site-btn-primary;
                                        width: 100%;
                                        justify-content: center;
                                        text-align: center;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
                .box1,.box2,.box3,.box4{
                    margin-bottom: 30px;
                }
            }
        } 
    }
}

//=======================  Carrers Start Here ========================//

//=======================  FOOTER-SECTION Start Here ========================//

.footer-div{
    .container{
        .footer-main{
            .icon-main{
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid $border-color;
                padding-bottom: 40px;
                @include breakpoint($secreen-max-lg){
                    flex-direction: column;
                    justify-content: center;
                }
                .footer-icon-div{
                    .icon-outer{
                        display: flex;
                        align-items: center;
                        .icon{
                            margin-right: 12px;
                            color: $primary-color;
                            font-size: 24px;
                        }
                        .icon-text{
                            font-family: 'Rubik';
                            font-weight: 400;
                            line-height: 32px;
                            color: $white-color;
                            letter-spacing: 1px;
                        }
                    }
                    .center{
                        @include breakpoint($secreen-max-lg){
                            margin: 12px 0;
                        }
                    }
                }
            }
        }
        .social-media{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            border-bottom: 2px solid $border-color;
            padding: 20px 0 40px 0;
            @include breakpoint($secreen-max-md){
               justify-content: center;
            }
            .social{
                .social-in{
                    color: $body-text-color;
                    padding: 20px 0 0 0;
                    transition: .3s;
                    @include breakpoint($secreen-max-md){
                        margin-right: 60px;
                    }
                    &:hover{
                        color: $white-color;
                    }
                }
            }
        } 
        .row-custom{
            margin: 72px 0;
            .col-otr{
                .col-inr{
                    @include breakpoint($secreen-max-md){
                        margin-bottom: 60px;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    .logo-img{
                        display: inline-flex;
                        .logo{
                            width: 180px;
                        }
                    }
                    .col-desc{
                        @include body-s;
                        color: $body-text-color;
                        font-family: $font-family-Rubik-Regular;
                        margin-top: 24px;
                    }
                }
            }
            .navigation{
                .col-navigation{
                    @include breakpoint($secreen-max-md){
                        margin-bottom: 60px;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    .navigation-heading{
                        color: $white-color;
                        margin-bottom: 16px;
                    }
                    .navigation-ul{
                        .navigation-li{
                            &:not(:last-child){
                                margin-bottom: 8px;
                            }
                            .navigation-a{
                                @include body-s;
                                color: $body-text-color;
                                font-family: $font-family-Rubik-Regular;
                                transition: .3s;
                                display: inline-flex;
                                &:hover{
                                    color: $white-color;
                                }
                            }
                        }
                    }
                }
            }
            .company{
                .col-company{
                    @include breakpoint($secreen-max-md){
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    .company-heading{
                        color: $white-color;
                        margin-bottom: 16px;
                    }
                    .company-ul{
                        .company-li{
                            &:not(:last-child){
                                margin-bottom: 8px;
                            }
                            .company-a{
                                @include body-s;
                                color: $body-text-color;
                                font-family: $font-family-Rubik-Regular;
                                transition: .3s;
                                display: inline-flex;
                                &:hover{
                                    color: $white-color;
                                }
                            }
                        }
                    }
                }
            }
        }
        .copy-main{
            border-top: 1px solid $border-color;
            padding: 20px 0;
            .copy-text{
                text-align: center;
                @include body-s;
                color: $body-text-color;
                font-family: $font-family-Rubik-Regular;
                .coin{
                    display: inline-flex;
                    color: $body-text-color;
                    font-family: $font-family-Rubik-Regular;
                    .dash{
                        color: $primary-color;
                    }
                }
            }
        }
    }
}
.back-to-top{
    position: fixed;
    right: 15px;
    bottom: 15px;
    .top-btn{
        width: 52px;
        height: 52px;
        background-color: $primary-color;
        border-radius: 100%;
        position: relative;
        opacity: 0;
        .arrow-up{
            position: absolute;
            top: 55%;
            left: 49%;
            transform: translate(-50%, -50%);
            color: black;
            font-size: 30px;
        }
    }
}
#myBtn{
    transition: .3s;
}

//=======================  FOOTER-SECTION End Here ========================//
