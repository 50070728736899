
//Dark Mode

.darkmode--activated .cover-single-creator{
    position: relative;
}
.darkmode--activated .cover-single-creator{
    position: relative;
}
.darkmode--activated .user-info{
    position: relative;
}
.darkmode--activated .user-info .left .username{
    color: black !important;
}
.darkmode--activated .user-info .wrapper .left .user-address .address{
    color: #666666 !important;
}
.darkmode--activated .user-info .wrapper .left .user-address .dot{
    color: #666666 !important;
}
.darkmode--activated .user-info .wrapper .left .user-address .user-link{
    color: #892CDC !important;
}
.darkmode--activated .user-info .wrapper .left .user-address .icon-copy-otr .copy-text{
    background-color: #FFD700 !important;
    color: black !important;
}
.darkmode--activated .user-info .wrapper .left .user-address .icon-copy-otr .copy-icon{
    color: #666666 !important;
}
.darkmode--activated .user-info .wrapper .right .follow-btn{
    background-color: #FFD700 !important;
    color: black !important;
}
.darkmode--activated .user-info .wrapper .right .follow-btn:hover{
    border: 1px solid #892CDC !important;
    background-color: #892CDC !important;
    color: white !important;
}
.darkmode--activated .user-info .wrapper .right .icon-otr .copy-text{
    background-color: #FFD700 !important;
    color: black !important;
}
.darkmode--activated .user-info .wrapper .right .icon-otr .icon{
    color: #666666 !important;
}
.darkmode--activated .tabs-mainn{
    position: relative;
}
.darkmode--activated .tabs-mainn .nav-pills-custom::after{
    background-color: #EEEEEE !important;
}
.darkmode--activated .tabs-mainn .nav-pills-custom .nav-link-custom{
    color: #666666 !important;
}
.darkmode--activated .tabs-mainn .nav-pills-custom .active{
    color: #892CDC !important;
    border-bottom: 1px solid #892CDC !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-about .left .desc{
    color: #666666 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-about .right .social-a{
    background-color: #EEEEEE !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-about .right .social-a .social-icon{
   color: #666666 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-about .right .social-address{
    color: #666666 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .art{
    background-color: #EEEEEE !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .art .abstract{
    color: #000 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .art .username{
    color: #999999 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .bit-main-otr{
    background-color: #FFD700 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .bit-main-otr .highest-bit{
    color: #666666 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .bit-main-otr .ending-main .ending{
    color: #666666 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .bit-main-otr .ending-main .dot{
    color: #666666 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .bit-main-otr .dot{
    color: #666666 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .bit-main-otr .ending-main .price{
    color: #000 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .bit-main-otr .ETH{
    color: #000 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .bit-main-otr .ending-main .clock-auctions1{
    color: #000 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .bit-main-otr .ending-main .clock-auctions2{
    color: #000 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .bit-main-otr .ending-main .clock-auctions3{
    color: #000 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .bit-main-otr .ending-main .clock-auctions4{
    color: #000 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .bit-main-otr .ending-main .clock-auctions5{
    color: #000 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .bit-main-otr .ending-main .clock-auctions6{
    color: #000 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .bit-main-otr .ending-main .clock-auctions1{
    color: #000 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .bit-main-otr .ending-main .clock-auctions7{
    color: #000 !important;
}
.darkmode--activated .tabs-mainn .tab-pane-custom .tab-creations .bit-main-otr .ending-main .clock-auctions8{
    color: #000 !important;
}
.darkmode--activated .tab-owenr .creator-name{
    color: black !important;
}
.darkmode--activated .tab-owenr .creator-address{
    color: #999999 !important;
}
.darkmode--activated .tab-history .creator-name{
    color: black !important;
}
.darkmode--activated .tab-history .creator-address{
    color: #999999 !important;
}

//Dark Mode



.cover-single-creator{
    background-image: url('/assets/img/cover-img.jpg');
    background-size: cover;
    .container{
        .wrapper{
            position: relative;
            height: 300px;
            .circle-img{
                position: absolute;
                bottom: -36px;
                left: 0;
                border-radius: 100%;
                border: 4px solid $primary-color;
            }
        }
    }
}
.user-info{
    .container{
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 56px 0 72px 0;
            @include breakpoint($secreen-max-md){
                flex-direction: column;
                align-items: baseline;
            }
            .left{
                @include breakpoint($secreen-max-md){
                    margin-bottom: 30px;
                }
                .username{
                    color: $white-color;
                    margin: 0 0 8px 0
                }
                .user-address{
                    display: flex;
                    align-items: center;
                    .address{
                        @include body-s;
                        color: $body-text-color;
                    }
                    .dot{
                        color: $body-text-color;
                        margin: 0 5px;
                    }
                    .user-link{
                        @include body-s;
                        color: $body-text-color-2;
                    }
                    .icon-copy-otr{
                        position: relative;
                        &:hover{
                            .copy-text{
                                top: -50px;
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                        .copy-icon{
                            font-size: 20px;
                            color: $body-text-color;
                            margin-left: 12px;
                        }
                        .copy-text{
                            padding: 4px 12px;
                            background-color: $border-color;
                            color: $white-color;
                            @include body-s;
                            border-radius: 4px;
                            visibility: hidden;
                            opacity: 0;
                            border-radius: 4px;
                            position: absolute;
                            top: -70px;
                            left: -25px;
                            width: 100px;
                            text-align: center;
                            transition: .3s;
                        }
                    }
                }
            }
            .right{
                display: flex;
                align-items: center;
                .follow-btn{
                    @include site-btn;
                    border: 1px solid transparent;
                }
                .icon-otr{
                    position: relative;
                    &:hover{
                        .copy-text{
                            top: -50px;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    &:not(:last-child){
                        margin-right: 40px;
                    }
                    .icon{
                        font-size: 20px;
                        color: $body-text-color;
                    }
                    .copy-text{
                        padding: 4px 12px;
                        background-color: $border-color;
                        color: $white-color;
                        @include body-s;
                        border-radius: 4px;
                        visibility: hidden;
                        opacity: 0;
                        border-radius: 4px;
                        position: absolute;
                        top: -70px;
                        left: 100%;
                        transform: translateX(-100%);
                        width: 100px;
                        text-align: center;
                        transition: .3s;
                    }
                }
            }
        }
    }
}
.tabs-mainn{
    margin: 0 0 96px 0;
    .container{
        .wrapper{
            .nav-pills-custom{
                position: relative;
                margin: 50px 0;
                &::after{
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $border-color;
                }
                .nav-item-custom{
                    &:not(:last-child){
                        margin-right: 32px;
                    }
                    .nav-link-custom{
                        @include body-s;
                        font-family: $font-family-Rubik-Medium;
                        color: $body-text-color;
                        padding: 0 0 16px 0;
                        transition: .3s;
                        background-color: transparent;
                        border: none;
                        &:hover{
                            color: $white-color;
                        }
                    }
                    .active{
                        border-bottom: 1px solid $primary-color;
                        color: $white-color;
                        position: relative;
                        z-index: 1;
                    }
                }
            }
            .tab-content-custom{
                .tab-pane-custom{
                    .tab-about{
                        display: flex;
                        align-items: baseline;
                        justify-content: space-between;
                        margin-top: 48px;
                        @include breakpoint($secreen-max-md){
                            flex-direction: column;
                        }
                        .left{
                            @include breakpoint($secreen-max-md){
                                margin-bottom: 60px;
                            }
                            .desc{
                                @include body-s;
                                color: $body-text-color;
                            }
                            .para{
                                margin-top: 16px;
                            }
                        }
                        .right{
                            .social-ul{
                                .social-li{
                                    display: flex;
                                    align-items: center;
                                    &:not(:last-child){
                                        margin-bottom: 16px;
                                    }
                                    .social-a{
                                        width: 52px;
                                        height: 52px;
                                        background-color: $border-color;
                                        border-radius: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        margin-right: 16px;
                                        .social-icon{
                                            color: $body-text-color;
                                        }
                                    }
                                    .social-address{
                                        @include body-s;
                                        color: $body-text-color;
                                    }
                                }
                            }
                        }
                    }
                    .tab-creations{
                        margin: 48px 0 0 0;
                        @include breakpoint($secreen-max-sm){
                            justify-content: center;
                        }
                        @include breakpoint($secreen-xs){
                            width: 400px;
                            margin: 48px auto 0 auto;
                        }
                        @include breakpoint($secreen-xxs){
                            width: 100%;
                        }
                        .col-otr{
                            .col-inr{
                                &:hover{
                                    cursor: pointer;
                                }
                                .img-otr{
                                    .auction-img{
                                        width: auto;
                                        border-radius: 4px;
                                    }
                                }
                                .art{
                                    padding: 20px 24px 28px 24px;
                                    background-color: $body-color-2;
                                    .abstract{
                                        color: $white-color;
                                        @include body-m;
                                        font-family: $font-family-Rubik-Regular;
                                    }
                                    .user-profile{
                                        display: flex;
                                        align-items: center;
                                        margin: 12px 0 0 0;
                                        .user-img{
                                            width: 32px;
                                            height: 32px;
                                            border-radius: 4px;
                                        }
                                        .username{
                                            @include body-s;
                                            color: $body-text-color;
                                            margin-left: 12px;
                                        }
                                    }
                                }
                                .bit-main-otr{
                                    padding: 16px 24px;
                                    background-color: $border-color;
                                    .bit-inr{
                                        display: flex;
                                        align-items: center;
                                        @include body-s;
                                        color: $body-text-color;
                                        margin: 0 0 4px 0;
                                        @include breakpoint($secreen-lg){
                                            flex-direction: column;
                                            align-items: baseline;
                                        }
                                        .dot{
                                            margin: 0 8px;
                                            @include breakpoint($secreen-lg){
                                                display: none;
                                            }
                                        }
                                        .ETH{
                                            color: $primary-color;
                                        }
                                    }
                                    .ending-main{
                                        display: flex;
                                        align-items: center;
                                        @include body-s;
                                        color: $body-text-color;
                                        @include breakpoint($secreen-lg){
                                            flex-direction: column;
                                            align-items: baseline;
                                            margin: 4px 0 0 0;
                                        }
                                        .dot{
                                            margin: 0 8px;
                                            @include breakpoint($secreen-lg){
                                                display: none;
                                            }
                                        }
                                        .clock-auctions1,.clock-auctions2,.clock-auctions3,.clock-auctions4,.clock-auctions5,.clock-auctions6,.clock-auctions7,.clock-auctions8{
                                            color: $primary-color;
                                        }
                                        .price{
                                            @include body-s;
                                            color: $primary-color;
                                        }
                                    }
                                }
                            }
                            .box1,.box2,.box3,.box4{
                                margin-bottom: 30px;
                            }
                            .box5,.box6{
                                @include breakpoint($secreen-max-md){
                                    margin-bottom: 30px;
                                }
                            }
                            .box7{
                                @include breakpoint($secreen-max-sm){
                                    margin-bottom: 30px;
                                }
                            }
                        }
                    }
                    .tab-owenr{
                        margin-top: 48px;
                        .col-otr{
                            .col-inr{
                                .featured-link{
                                    display: flex;
                                    align-items: center;
                                    .img-otr{
                                        width: 52px;
                                        height: 52px;
                                        .featured-img{
                                            border-radius: 4px;
                                        }
                                    }
                                    .creator-user{
                                        margin-left: 16px;
                                        .creator-name{
                                            color: $white-color;
                                            @include body-m;
                                            font-family: $font-family-Rubik-Medium;
                                        }
                                        .creator-address{
                                            @include body-s;
                                            color: $body-text-color;
                                        }
                                    }
                                }
                            }
                            .box1,.box2,.box3,.box4,.box5,.box6,.box7{
                                margin-bottom: 30px;
                            }
                        }
                    }
                    .tab-history{
                        margin-top: 48px;
                        .col-otr{
                            .col-inr{
                                .featured-link{
                                    display: flex;
                                    align-items: center;
                                    .img-otr{
                                        width: 52px;
                                        height: 52px;
                                        .featured-img{
                                            border-radius: 4px;
                                        }
                                    }
                                    .creator-user{
                                        margin-left: 16px;
                                        .creator-name{
                                            color: $white-color;
                                            @include body-m;
                                            font-family: $font-family-Rubik-Medium;
                                        }
                                        .creator-address{
                                            @include body-s;
                                            color: $body-text-color;
                                        }
                                    }
                                }
                            }
                            .box1,.box2,.box3,.box4,.box5,.box6,.box7{
                                margin-bottom: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}