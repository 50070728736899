

//All Creators

.darkmode--activated .all-creators{
    position: relative;
}
.darkmode--activated .all-creators .heading{
    color: black !important;
}
.darkmode--activated .all-creators .heading{
    color: black !important;
}
.darkmode--activated .all-creators .creator-name{
    color: black !important;
}
.darkmode--activated .all-creators .creator-address{
    color: #999999 !important;
}

//All Creators

//==================== All Creators Start Here =====================//

.all-creators{
    padding: 72px 0 96px 0;
    .container{
        .heading{
            color: $white-color;
        }
        .row-custom{
            margin: 48px 0 0 0;
            .col-otr{
                .col-inr{
                    .featured-link{
                        display: flex;
                        align-items: center;
                        .img-otr{
                            width: 52px;
                            height: 52px;
                            .featured-img{
                                border-radius: 4px;
                            }
                        }
                        .creator-user{
                            margin-left: 16px;
                            .creator-name{
                                color: $white-color;
                                @include body-m;
                                font-family: $font-family-Rubik-Medium;
                            }
                            .creator-address{
                                @include body-s;
                                color: $body-text-color;
                            }
                        }
                    }
                }
                .box1,.box2,.box3,.box4,.box5,.box6,.box7,.box8,.box9,.box10,.box11,.box12,.box13,.box14,.box15,.box16{
                    margin-bottom: 30px;
                }
                .box17,.box18{
                    @include breakpoint($secreen-max-md){
                        margin-bottom: 30px;
                    }
                }
                .box19{
                    @include breakpoint($secreen-max-sm){
                        margin-bottom: 30px;
                    }
                }
            }
        } 
    }
}

//==================== All Creators End Here =====================//